import React from 'react'
import { styled } from '@linaria/react'
import { BookAFreeMeetingButton, SectionContainer } from '~/features/service/components'
import { ServicesMain, Steps, Tagline } from './components'
import { mainTexts } from '../../_DATA'

interface Props {}

export const Main = ({}: Props) => {
  const { services, steps, tagline } = mainTexts
  return (
    <StyledSectionContainer>
      <Tagline title={tagline.title} subTitle={tagline.subtitle} text={tagline.text} />
      <Steps content={steps} />
      <ServicesMain content={services} />
      <BookAFreeMeetingButton />
    </StyledSectionContainer>
  )
}

const StyledSectionContainer = styled(SectionContainer)`
`
