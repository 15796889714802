export const FAQTexts = [
  {
    question: 'What makes your FinOps tool different from others?',
    answer:
      'Our FinOps tool is designed to align directly with your business goals, focusing on saving money where it matters most. Unlike traditional models that benefit from high cloud spend, our gainshare approach ties our success to yours—ensuring our profits grow only when your costs shrink. This alignment creates a true incentive to identify inefficiencies, reduce waste, and deliver lasting savings, regardless of the size of your cloud spend. Our commitment is clear: we succeed by helping you save.',
  },
  {
    question: 'How does the gainshare model work?',
    answer:
      'We earn only when you save. Our fees are tied to the cost savings we achieve for you, ensuring no upfront costs or financial risk.',
  },
  {
    question: 'Can you guarantee cost savings?',
    answer:
      'While savings vary by client, most see immediate improvements in cloud efficiency, often achieving 10–15% savings within the first assessment.',
  },
  {
    question: 'Is your service suitable for my company?',
    answer:
      'Our solutions are tailored to fit businesses of all sizes, industries, and cloud environments, ensuring relevance and value.',
  },
  {
    question: 'Will onboarding disrupt our current operations?',
    answer:
      'Not at all. Our process is seamless, requiring minimal input from your team while ensuring continuity in your operations.',
  },
  {
    question: 'Do you work with companies in my industry?',
    answer:
      'Yes, our team has diverse experience across industries, enabling us to address specific challenges unique to your field.',
  },
  {
    question: 'How do you ensure data security?',
    answer:
      'We adhere to strict security protocols, ensuring your data remains confidential and is only used for optimization purposes.',
  },
  {
    question: 'What happens after the initial assessment?',
    answer:
      'We provide actionable insights, implement optimization strategies, and work with your team to ensure sustainable results.',
  },
  {
    question: 'How do you ensure long-term savings?',
    answer:
      'We empower your team with tools and training, leaving you with a self-sufficient cloud management framework when our engagement ends.',
  },
  {
    question: 'What should I prepare before booking a demo?',
    answer:
      'No preparation is needed. Simply book a demo, and we’ll guide you through the process to assess fit and value.',
  },
]
