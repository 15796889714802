import React, { ReactNode, useEffect } from 'react'
import { use100vh } from 'react-div-100vh'
import { useTransition, type Stage } from 'transition-hook'
import { styled } from '@linaria/react'
import { Portal } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'
import { useEscKeyHandler, useMediaQuery } from '@/hooks'

interface Props {
  children: ReactNode | ReactNode[]
  closeHandler: () => void
  isVisible: boolean
  offsetTop?: string
  initialHeight?: string
  fillColor?: string
  renderInDOM?: boolean
  role?: 'dialog' | 'navigation'
}

export const PortalWrapper = ({
  children,
  isVisible,
  closeHandler,
  offsetTop,
  renderInDOM,
  role = 'dialog',
  initialHeight = '0',
  fillColor = colors.secondary.secondary,
}: Props) => {
  const isDesktop = useMediaQuery()
  const { stage, shouldMount } = useTransition(isVisible, isDesktop ? 100 : 200)
  useEscKeyHandler(closeHandler)

  const viewportHeight = use100vh()
  const portalHeight = offsetTop ? `calc(${viewportHeight}px - ${offsetTop})` : `${viewportHeight}px`

  useEffect(() => {
    if (!isVisible) return

    document.body.style.overflow = 'hidden'

    // eslint-disable-next-line consistent-return
    return () => {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.removeProperty('overflow')
        if (!document.body.style[0]) document.body.removeAttribute('style')
      }
    }
  }, [isVisible])

  if (renderInDOM)
    return (
      <PortalInnerDOM
        $isVisible={isVisible}
        $offsetTop={offsetTop ?? 0}
        $initialHeight={initialHeight}
        $portalHeight={portalHeight}
        $fillColor={fillColor}
        aria-modal="true"
        role={role}
        tabIndex={-1}
        data-testid="PortalWrapperDOM"
      >
        {children}
      </PortalInnerDOM>
    )

  return (
    !renderInDOM &&
    shouldMount && (
      <Portal zIndex={100000}>
        <PortalInnerStandalone
          $stage={stage}
          $offsetTop={offsetTop ?? 0}
          $initialHeight={initialHeight}
          $portalHeight={portalHeight}
          $fillColor={fillColor}
          aria-modal="true"
          role={role}
          tabIndex={-1}
          data-testid="PortalWrapperStandalone"
        >
          {children}
        </PortalInnerStandalone>
      </Portal>
    )
  )
}

const PortalViewCommon = styled.div<{
  $offsetTop: string | number
  $portalHeight: string
  $initialHeight: string
  $fillColor
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  width: 100vw;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s, 0.15s;
  transition-property: max-height, opacity;
  top: ${({ $offsetTop }) => $offsetTop};
  height: ${({ $portalHeight }) => $portalHeight};
  background-color: ${({ $fillColor }) => $fillColor};

  ${desktopSmallMedia} {
    transition-duration: 0.1s, 0.05s;
  }
`

const PortalInnerStandalone = styled(PortalViewCommon)<{
  $stage: Stage
}>`
  opacity: ${({ $stage }) => ($stage === 'enter' ? 1 : 0)};
  max-height: ${({ $stage, $portalHeight, $initialHeight }) => ($stage === 'enter' ? $portalHeight : $initialHeight)};
`
const PortalInnerDOM = styled(PortalViewCommon)<{
  $isVisible: boolean
}>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'all' : 'none')};
  max-height: ${({ $isVisible, $portalHeight, $initialHeight }) => ($isVisible ? $portalHeight : $initialHeight)};
`
