import React from 'react'
import { styled } from '@linaria/react'
import { css, cx } from '@linaria/core'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'
import { SmallTitle } from '../../../components'
import { makeTextBaseStyles } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'

export type StepType = {
  title: string
  text: string
  index: number
}

interface Props {
  step: StepType
  isFirstStep: boolean
  isLastStep: boolean
}

export const SingleStep = ({ step, isFirstStep, isLastStep }: Props) => {
  return (
    <SingleStepView>
      <GraphicsBlock
        className={cx(
          startLineCommon,
          endLineCommon,
          isFirstStep ? startLineDashed : startLineSolid,
          isLastStep ? endLineDashed : endLineSolid,
        )}
      >
        <Cylinder>{step.index}</Cylinder>
      </GraphicsBlock>
      <TextsBlock>
        <SmallTitle $isCentered>{step.title}</SmallTitle>
        <Text>{step.text}</Text>
      </TextsBlock>
    </SingleStepView>
  )
}

const startLineCommon = css`
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    height: 50%;

    ${media.desktopSmall} {
      top: 50%;
      left: 0;
      width: 50%;
      height: unset;
    }
  }
`
const endLineCommon = css`
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    height: 50%;

    ${media.desktopSmall} {
      top: 50%;
      left: 50%;
      width: 50%;
      height: unset;
    }
  }
`

const startLineSolid = css`
  &:before {
    border-left: var(--step-line-solid);

    ${media.desktopSmall} {
      border-bottom: var(--step-line-solid);
    }
  }
`
const endLineSolid = css`
  &:after {
    border-left: var(--step-line-solid);

    ${media.desktopSmall} {
      border-bottom: var(--step-line-solid);
    }
  }
`

const gradient = 'transparent 0%, black 40%, black 100%'

const startLineDashed = css`
  &:before {
    -webkit-mask-image: var(--gradient-line);
    mask-image: var(--gradient-line);
    --gradient-line: linear-gradient(to bottom, ${gradient});
    border-left: var(--step-line-dashed);

    ${media.desktopSmall} {
      --gradient-line: linear-gradient(to right, ${gradient});
      border-bottom: var(--step-line-dashed);
      border-left: unset;
    }
  }
`

const endLineDashed = css`
  &:after {
    -webkit-mask-image: var(--gradient-line);
    mask-image: var(--gradient-line);
    --gradient-line: linear-gradient(to top, ${gradient});
    border-left: var(--step-line-dashed);
    ${media.desktopSmall} {
      --gradient-line: linear-gradient(to left, ${gradient});
      border-bottom: var(--step-line-dashed);
      border-left: unset;
    }
  }
`

const SingleStepView = styled.div`
  --step-line-solid: 2px solid ${colors.secondary.secondary};
  --step-line-dashed: 1px dashed #43acda;
  display: grid;
  align-content: start;
  grid-row-gap: 3rem;
  grid-column-gap: 1.5rem;
  grid-template-columns: 3rem auto;

  ${media.desktopSmall} {
    grid-template-columns: none;
  }
`

const GraphicsBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: relative;
  width: 3rem;
  height: 100%;

  ${media.desktopSmall} {
    flex-direction: row;
    height: 3rem;
    width: 100%;
  }
`

const Cylinder = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: ${colors.white};
  border-radius: 50%;
  border: ${pxToRem(3)} solid ${colors.secondary.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(18)};
  font-weight: 500;
  letter-spacing: 0.01em;
  padding-top: ${pxToRem(3)};
`

const TextsBlock = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  align-items: start;
  align-content: start;
  padding: ${pxToRem(24, 24, 24, 0)};

  ${media.desktopSmall} {
    grid-row-gap: 1.5rem;
    padding: ${pxToRem(0, 24)};
  }
`

const Text = styled.span`
  ${makeTextBaseStyles({})};

  ${media.desktopSmall} {
    text-align: center;
  }
`
