import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from '@linaria/core'
import { media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { Typography } from '@/UI'
import { useGetImagesFromAssets } from '@/hooks'
import { SectionContainer, TitleBlock } from '../components'
import { finOpsTexts } from '../_DATA'

interface Props {}

export const FinOps = ({}: Props) => {
  const logo = useGetImagesFromAssets({ name: finOpsTexts.logo })[0]
  const picture = useGetImagesFromAssets({ name: finOpsTexts.picture })[0]

  return (
    <SectionContainer appearance="gray">
      <FinOpsView>
        <Texts>
          <TitleBlock
            title={finOpsTexts.title}
            subTitle={finOpsTexts.subTitle}
            subtitleColor="gray"
            alignment="start"
          />
          <Text dangerouslySetInnerHTML={{ __html: finOpsTexts.text }} $bodyVariant="small" />
          <GatsbyImage
            image={logo.childImageSharp.gatsbyImageData}
            alt="FinOps logo"
            className={css`
              width: ${pxToRem(120)};
              height: ${pxToRem(39)};
            `}
            imgStyle={{
              mixBlendMode: 'darken',
            }}
          />
        </Texts>
        <GatsbyImage
          image={picture.childImageSharp.gatsbyImageData}
          alt="FinOps visual"
          imgStyle={{
            objectFit: 'contain',
            objectPosition: '50% 50%',
          }}
          className={css`
            max-width: ${pxToRem(640)};
          `}
          objectFit="contain"
        />
      </FinOpsView>
    </SectionContainer>
  )
}

const FinOpsView = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  grid-column-gap: 4.5rem;

  ${media.desktopSmall} {
    align-content: center;
    align-items: center;
    grid-template-columns: auto ${pxToRem(532)};
  }
`

const Texts = styled.div`
  grid-gap: 1.5rem;
  display: grid;
`

const Text = styled(Typography)`
  padding: ${pxToRem(24, 0, 20, 24)};
  border-left: 1px solid rgba(39, 146, 96, 0.3);

  ${media.desktopSmall} {
    padding: ${pxToRem(24, 0, 20, 32)};
  }
`
