import React from 'react'
import { styled } from '@linaria/react'
import { pxToRem } from '@/utils/unitsInRem'
import { AccordionSectionContentType } from './types'
import { AccordionSection } from './AccordionSection'
import { Styleable } from '@/lib/interfaces'

interface Props extends Styleable {
  content: AccordionSectionContentType[]
}

export const Accordion = ({ content, className }: Props) => {
  return (
    <AccordeonView className={className}>
      {content.map(({ title, text }, index) => (
        <AccordionSection key={`${title}__${index}`} title={title} text={text} />
      ))}
    </AccordeonView>
  )
}

const AccordeonView = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: ${pxToRem(16)};
`
