import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { Stage, useTransition } from 'transition-hook'
import { Styleable } from '@/lib/interfaces'

interface TransitionFadeProps extends Styleable {
  isVisible?: boolean
  children: ReactNode
  duration?: number
  durationOut?: number
}

export function TransitionFade({
  className,
  isVisible,
  children,
  duration = 200,
  durationOut = duration,
}: TransitionFadeProps) {
  const { stage, shouldMount } = useTransition(isVisible, duration)

  return (
    shouldMount && (
      <ChildrenWrapper
        className={className}
        $stage={stage}
        $duration={duration / 1000}
        $durationOut={durationOut / 1000}
      >
        {children}
      </ChildrenWrapper>
    )
  )
}

const ChildrenWrapper = styled.div<{ $stage: Stage; $duration: number; $durationOut: number }>`
  transition-timing-function: ease-in-out;
  transition-duration: ${({ $stage, $duration, $durationOut }) =>
    getDurationFromTransition($stage, $duration, $durationOut)};
  opacity: ${({ $stage }) => getOpacityFromTransition($stage)};
`

function getOpacityFromTransition(stage: Stage): number {
  if (stage === 'from') return 0
  if (stage === 'enter') return 1
  if (stage === 'leave') return 0

  return 0
}

function getDurationFromTransition(stage: Stage, duration: number, durationOut: number): string {
  if (stage === 'from') return `${duration}s`
  if (stage === 'enter') return `${duration}s`
  if (stage === 'leave') return `${durationOut}s`

  return `${duration}s`
}
