import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { VideoType } from './types'
import { AspectRatioContainer, selectVideo, BoxShadowStyle } from '@/UI'

interface VideoThumbnailProps {
  picture: VideoType['localThumbnail']
  alt: string
  className?: string
}

interface VideoThumbnailInteractiveProps extends VideoThumbnailProps {
  videoId: string
}

export const VideoThumbnail = ({ picture, alt, className }: VideoThumbnailProps) => {
  const thumbnail = getImage(picture)

  return (
    <AspectRatioContainer verticalRatio="56%" className={className}>
      <GatsbyImage alt={alt} image={thumbnail} />
      <PseudoButtonContainer>
        <svg viewBox="0 0 48 48" fill="#fff" width="100%" height="100%">
          <path d="m24 4a20 20 0 0 0 -20 20 20 20 0 0 0 20 20 20 20 0 0 0 20-20 20 20 0 0 0 -20-20zm-5.6973 11.287 7.5449 4.3555 7.5469 4.3574-7.5469 4.3574-7.5449 4.3555v-8.7129z" />
        </svg>
      </PseudoButtonContainer>
    </AspectRatioContainer>
  )
}

export const VideoThumbnailInteractive = ({ videoId, ...thumbnailProps }: VideoThumbnailInteractiveProps) => (
  <div onClick={() => selectVideo(videoId)}>
    <VideoThumbnailWithShadow {...thumbnailProps} />
  </div>
)

const PseudoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 96px;
  height: 96px;
  opacity: 0.5;
  z-index: 1;
  pointer-events: none;
  transform: translate(-50%, -50%);

  & svg {
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.65));
  }
`

const VideoThumbnailWithShadow = styled(VideoThumbnail)`
  ${BoxShadowStyle};
`
