export const mainTexts = {
  tagline: {
    title: 'How we solve it',
    subtitle:
      'Streamlining cloud operations by addressing inefficiencies, aligning goals, and empowering teams to drive innovation',
    text: `<p>Our journey with clients starts with a comprehensive initial assessment to uncover inefficiencies and potential savings.</p>
      <p>From there, we craft a tailored optimization strategy that not only reduces costs but enhances the effectiveness of your cloud operations.</p>
      <p>Over the course of our engagement, we work closely with your team, sharing knowledge and tools so they’re empowered to maintain and improve your cloud environment long after our collaboration ends.</p>`,
  },
  steps: [
    {
      index: 1,
      title: 'Initial Assessment',
      text: 'Identify inefficiencies and uncover 10–15% savings immediately',
    },
    {
      index: 2,
      title: 'Optimization',
      text: 'Tailor solutions that deliver up to 40% cost reductions',
    },
    {
      index: 3,
      title: 'Knowledge Transfer',
      text: 'Knowledge Transfer – Train your team to achieve long-term success',
    },
    {
      index: 4,
      title: 'Transition',
      text: 'Leave your cloud in a stronger position, with a self-sufficient team',
    },
  ],
  services: [
    {
      title: 'Cloud Assessment',
      text: 'Reveal Hidden Cost Leaks and Chart Your Path to Savings'
    },
    {
      title: 'Cloud Optimization',
      text: 'End Resource Waste and Lower Your Monthly Bill'
    },
    {
      title: 'Cloud Licensing',
      text: 'Untangle Vendor Deals and Stay in Control of Your Budget'
    },
    {
      title: 'Cloud Expert on Demand',
      text: 'Get FinOps Advice Exactly When You Need It'
    },
  ]


}
