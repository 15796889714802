import React from 'react'
import { styled } from '@linaria/react'
import { Button } from '@/UI'
import { useBookACall } from '@/hooks'

interface Props {}

export const BookAFreeMeetingButton = ({}: Props) => {
  const bookACallHandler = useBookACall()

  return <StyledButton size="large" type="filled" onClick={bookACallHandler}>Book a free meeting</StyledButton>
}

const StyledButton = styled(Button)`
  align-self: center;
`
