import React, { MouseEvent, TouchEvent } from 'react'
import { styled } from '@linaria/react'
import { Ripple } from '@/UI'
import { colors, desktopSmallMedia, fontLeagueSpartan, hoverMedia, theme } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { Styleable, WithChildren } from '@/lib/interfaces'

type Type = 'dark' | 'light' | 'secondary' | 'filled'
export type ButtonState = 'isPositive' | 'isNegative' | 'isDisabled' | 'isPending'
type Size = 'large' | 'standard' | 'small'
type DesktopWidth = 'fit-content' | '100%' | string

interface Props extends WithChildren, Styleable {
  type?: Type
  state?: ButtonState
  size?: Size
  rippleDuration?: string
  desktopWidth?: DesktopWidth
  preventDefaultClick?: boolean
  onClick?: () => void
}

const buttonSizes: Record<Size, { height: number; padding: number }> = {
  large: {
    height: 64,
    padding: 48,
  },
  standard: {
    height: 48,
    padding: 24,
  },
  small: {
    height: 36,
    padding: 16,
  },
}

export const Button = ({
  children,
  className,
  onClick,
  state,
  rippleDuration,
  preventDefaultClick = true,
  desktopWidth = 'fit-content',
  size = 'standard',
  type = 'light',
}: Props) => {
  const clickHandler = (e: MouseEvent | TouchEvent) => {
    if (preventDefaultClick) e.preventDefault()
    if (onClick) onClick()
  }

  return (
    <ButtonView
      className={className}
      onClick={clickHandler}
      $type={type}
      $state={state}
      $size={size}
      $desktopWidth={desktopWidth}
      disabled={Boolean(state)}
    >
      {children}
      <Ripple duration={rippleDuration} fillColor={type === 'secondary' ? colors.primary.primary : undefined} />
    </ButtonView>
  )
}

interface RootProps {
  $type: Type
  $size: Size
  $desktopWidth: DesktopWidth
  $state?: ButtonState
}

const ButtonView = styled.button<RootProps>`
  --button-color: ${({ $state, $type }) => {
    switch (true) {
      case $type === 'filled':
        return colors.textStyles.inverse
      case $type === 'secondary':
        return colors.secondary.secondary
      case $state === 'isDisabled' && $type === 'dark':
        return colors.tertiary.tertiary
      case $state === 'isDisabled' && $type === 'light':
        return colors.primary.lightest
      case $state === 'isPositive':
        return colors.outcome.positive.darkest
      case $state === 'isNegative':
        return colors.outcome.negative.darkest
      default:
        return colors.primary.primary
    }
  }};
  --button-border-color: ${({ $state, $type }) => {
    switch (true) {
      case $type === 'secondary':
        return colors.secondary.secondary
      case $state === 'isDisabled' && $type === 'dark':
        return colors.tertiary.tertiary
      case $state === 'isDisabled' && $type === 'light':
        return colors.primary.lightest
      case $state === 'isPositive':
        return colors.outcome.positive.lightest
      case $state === 'isNegative':
        return colors.outcome.negative.lightest
      default:
        return colors.primary.primary
    }
  }};
  --button-background-color: ${({ $state, $type }) => {
    switch (true) {
      case $type === 'filled':
        return colors.primary.primary
      case $state === 'isPositive':
        return colors.outcome.positive.lightest
      case $state === 'isNegative':
        return colors.outcome.negative.lightest
      default:
        return 'transparent'
    }
  }};
  --button-background-color-action: ${({ $type }) =>
    $type === 'filled' ? colors.primary.dark : 'var(--button-background-color)'};
  --button-color-action: ${({ $type }) => ($type === 'filled' ? colors.primary.lightest : 'var(--button-color)')};
  --button-box-shadow-common: 0 0 ${pxToRem(7)} 0 ${theme.hover}
  --button-box-shadow-action: ${({ $type }) => ($type !== 'filled' ? 'var(--button-box-shadow-common)' : 'unset')};

  font-family: ${fontLeagueSpartan};
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  user-select: none;
  flex-shrink: 0;
  border-radius: ${pxToRem(8)};
  font-size: ${pxToRem(16)};
  font-weight: 700;
  line-height: 113%;
  letter-spacing: 0.015em;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  pointer-events: ${({ $state }) => ($state ? 'none' : 'all')};
  border-width: ${pxToRem(2)};
  border-style: solid;
  transition-property: background-color, color, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  height: ${({ $size }) => pxToRem(buttonSizes[$size].height)};
  padding: 0 ${({ $size }) => pxToRem(buttonSizes[$size].padding)};
  padding-top: ${pxToRem(4)};
  color: var(--button-color);
  border-color: var(--button-border-color);
  background-color: var(--button-background-color);

  ${desktopSmallMedia} {
    width: ${({ $desktopWidth }) => $desktopWidth};
  }

  ${hoverMedia} {
    &:hover {
      box-shadow: var(--button-box-shadow-action);
      background-color: var(--button-background-color-action);
      color: var(--button-color-action);
    }
  }
  &:focus {
    box-shadow: var(--button-box-shadow-action);
    background-color: var(--button-background-color-action);
    color: var(--button-color-action);
  }

  &:active {
    box-shadow: none;
  }

  &:after {
    --animation-offset: ${pxToRem(20)};
    --stripe-color: ${({ $type }) => ($type === 'light' ? colors.primary.lightest : colors.primary.darkest)};
    content: '';
    display: ${({ $state }) => ($state === 'isPending' ? 'block' : 'none')};
    z-index: -1;
    position: absolute;
    left: calc(-1 * var(--animation-offset));
    top: 0;
    width: calc(100% + var(--animation-offset));
    height: 100%;
    background: repeating-linear-gradient(
      -55deg,
      transparent,
      transparent ${pxToRem(5)},
      var(--stripe-color) ${pxToRem(5)},
      var(--stripe-color) ${pxToRem(8)}
    );
    animation-name: MOVE-BG;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes MOVE-BG {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(var(--animation-offset));
      }
    }
  }
`
