import React from 'react'
import { styled } from '@linaria/react'
import { Styleable } from '@/lib/interfaces'

type DirectionType = 'left' | 'right'

interface Props extends Styleable {
  size?: number
  color?: string
  direction?: DirectionType
}

export const Arrow = ({ size = 20, color = 'currentColor', className, direction = 'left' }: Props) => {
  return (
    <ArrowContainer $size={size} className={className} $direction={direction}>
      <svg width={size} height={size} fill={color}>
        <use xlinkHref="#iconArrowLeft" />
      </svg>
    </ArrowContainer>
  )
}

const ArrowContainer = styled.div<{ $size: number; $direction: DirectionType }>`
  display: flex;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  transform: ${({ $direction }) => ($direction === 'right' ? 'scaleX(-1)' : 'none')};
`
