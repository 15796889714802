import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

interface ImageData {
  gatsbyImageData: IGatsbyImageData
}

export interface FileNode {
  name: string
  base: string
  relativePath: string
  relativeDirectory: string
  childImageSharp?: ImageData
}

export const useGetImagesFromAssets = ({ name, folder }: { name?: string; folder?: string }): FileNode[] => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
        nodes {
          name
          base
          relativePath
          relativeDirectory
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 50)
          }
        }
      }
    }
  `)

  const nodes = data.allFile.nodes as FileNode[]
  if (!name && !folder) return nodes
  if (name) return nodes.filter(node => node.name === name)
  if (folder) return nodes.filter(node => node.relativeDirectory === folder)
  return []
}
