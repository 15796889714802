import { styled } from '@linaria/react'
import { desktopSmallMedia } from '@/lib/theme'

export const HeaderSectionsItemsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: 48px;
  
  ${desktopSmallMedia} {
    grid-row-gap: 64px;
  }
`
