import React from 'react'

export function Sprite() {
  return (
    <svg style={{ display: 'none' }} aria-hidden="true" focusable="false">
      <symbol id="iconArrowLeft" viewBox="0 0 20 20">
        <path d="m2.086 10.692 5.906 5.912c.385.385 1.015.388 1.4.004.385-.385.379-1.014-.005-1.398l-4.227-4.226h12.059c.544 0 .992-.44.992-.984s-.448-.985-.992-.985h-12.059l4.226-4.224c.382-.381.365-1.007-.016-1.389-.382-.382-1.001-.382-1.383 0l-5.901 5.905c-.178.178-.29.422-.29.692s.112.515.29.693z" />
      </symbol>
    </svg>
  )
}
