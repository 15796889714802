import { styled } from '@linaria/react'
import { colors, media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { h1StylesBase } from '@/UI/typography-new'

export type H1VariantType = 'Large' | 'XXL'

interface Props {
  $variant?: H1VariantType
  $isInverse?: boolean
  $isCentered?: boolean
}

export const TitleMain = styled.h1<Props>`
  ${h1StylesBase};
  color: ${({ $isInverse }) => ($isInverse ? colors.textStyles.inverse : colors.textStyles.regular)};
  text-align: ${({ $isCentered }) => $isCentered ? 'center' : 'start'};

  ${media.desktopSmall} {
    font-size: ${({ $variant = 'Large' }) => ($variant === 'Large' ? pxToRem(60) : pxToRem(76))};
    font-weight: ${({ $variant = 'Large' }) => ($variant === 'Large' ? 600 : 500)};
    letter-spacing: ${({ $variant = 'Large' }) => ($variant === 'Large' ? '-0.01em' : '-0.015em')};
  }
`
