import React, { Fragment } from 'react'
import { styled } from '@linaria/react'
import { Typography } from '@/UI'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

type TableType = {
  rowsHeaders: string[]
  columns: {
    header: string
    row1st: string
    row2nd: string
  }[]
}

interface Props {
  table: TableType
}

export const CompTable = ({ table }: Props) => {
  return (
    <CompTableView>
      <TableBody>
        <ZeroPlaceholder />
        <LeftBadge $position="first">{table.rowsHeaders[0]}</LeftBadge>
        <LeftBadge $position="last">{table.rowsHeaders[1]}</LeftBadge>
        {table.columns.map((column, idx) => (
          <Fragment key={column.header}>
            <TableHeader $position={calculatePositionType(idx, table.columns.length)}>
              <HeaderText>{column.header}</HeaderText>
            </TableHeader>
            <TableCell $positionW={calculatePositionType(idx, table.columns.length)} $positionH="first">
              <Typography dangerouslySetInnerHTML={{ __html: column.row1st }} $bodyVariant="small" />
            </TableCell>
            <TableCell $positionW={calculatePositionType(idx, table.columns.length)} $positionH="last">
              <Typography dangerouslySetInnerHTML={{ __html: column.row2nd }} $bodyVariant="small" />
            </TableCell>
          </Fragment>
        ))}
      </TableBody>
    </CompTableView>
  )
}

type PositionType = 'first' | 'last' | 'regular'

function calculatePositionType(idx: number, itemsCount: number): PositionType {
  if (idx === 0) return 'first'
  if (idx === itemsCount - 1) return 'last'
  return 'regular'
}

const CompTableView = styled.div`
  margin-bottom: 48px;
  --table-border-radius: 1.5rem;
  --table-background-filled: #ffe3d7;
  --table-border: 1px solid ${colors.primary.lightest};
  --table-cell-w: minmax(${pxToRem(244)}, 1fr);
  --table-padding: ${pxToRem(24)};
  
  ${media.desktopSmall} {
    --table-padding: ${pxToRem(40)};
  }
`

const TableBody = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(160)} var(--table-cell-w) var(--table-cell-w) var(--table-cell-w) var(--table-cell-w);
  grid-template-rows: ${pxToRem(92)} 1fr 1fr;
  grid-auto-flow: column;
  filter: drop-shadow(0 4px 78px rgba(226, 145, 116, 0.25));
  overflow-x: scroll;
  border-radius: var(--table-border-radius);

  ${media.desktopMedium} {
    overflow-x: visible;
  }
`

const TableHeader = styled.div<{ $position: PositionType }>`
  padding: 2rem var(--table-padding);
  display: flex;
  align-items: center;
  height: ${pxToRem(92)};
  border-bottom: var(--table-border);
  border-right: ${({ $position }) => ($position !== 'last' ? 'var(--table-border)' : 'none')};
  background-color: ${({ $position }) => ($position === 'first' ? 'var(--table-background-filled)' : colors.white)};
  border-top-left-radius: ${({ $position }) => ($position === 'first' ? 'var(--table-border-radius)' : 'none')};
  border-top-right-radius: ${({ $position }) => ($position === 'last' ? 'var(--table-border-radius)' : 'none')};
`

const HeaderText = styled.span`
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(18)};
  font-weight: 500;
  line-height: 1;
  padding: ${pxToRem(8, 0, 8, 24)};
  border-left: 1px solid ${colors.primary.light};
`

const TableCell = styled.div<{ $positionW: PositionType; $positionH: PositionType }>`
  padding: var(--table-padding);
  display: flex;
  align-items: center;
  border-right: ${({ $positionW }) => ($positionW !== 'last' ? 'var(--table-border)' : 'none')};
  border-bottom: ${({ $positionH }) => ($positionH !== 'last' ? 'var(--table-border)' : 'none')};
  background-color: ${({ $positionW }) => ($positionW === 'first' ? 'var(--table-background-filled)' : colors.white)};
  border-bottom-right-radius: ${({ $positionW, $positionH }) =>
    $positionW === 'last' && $positionH === 'last' ? 'var(--table-border-radius)' : 'none'};
`

const ZeroPlaceholder = styled.div``

const LeftBadge = styled.div<{ $position: PositionType }>`
  padding: var(--table-padding);
  display: flex;
  align-items: center;
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(14)};
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background-color: ${colors.white};
  border-bottom: ${({ $position }) => ($position !== 'last' ? 'var(--table-border)' : 'none')};
  border-top-left-radius: ${({ $position }) => ($position === 'first' ? 'var(--table-border-radius)' : 'none')};
  border-bottom-left-radius: ${({ $position }) => ($position === 'last' ? 'var(--table-border-radius)' : 'none')};
`
