import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch'
import { searchIndices } from '_/config/search/searchIndices'
import { desktopSmallMedia } from '@/lib/theme'
import { mobSearchState } from './state'
import { ButtonContactCommon, Logo, PortalWrapperHeaderContent } from '../common'
import { useCloseSearchHandler } from '../../hooks'
import { SearchBox } from './query'
import { SearchResult } from './result'

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY)

export const Search = () => {
  const { isSearchVisible } = useSnapshot(mobSearchState)
  const closeSearchHandler = useCloseSearchHandler()

  return (
    <InstantSearch searchClient={searchClient} indexName={searchIndices[0].name}>
      <PortalWrapperHeaderContent closeHandler={closeSearchHandler} isVisible={isSearchVisible}>
        <Container $noMobilePadding={false}>
          <StyledLogo />
          <SearchBox />
          <ContactBtnDesktop showOn="desktop" />
        </Container>
        <SearchResult />
      </PortalWrapperHeaderContent>
    </InstantSearch>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const StyledLogo = styled(Logo)`
  display: none;

  ${desktopSmallMedia} {
    margin-right: 48px;
    display: flex;
  }
`

const ContactBtnDesktop = styled(ButtonContactCommon)`
  display: none;

  ${desktopSmallMedia} {
    margin-left: auto;
    display: flex;
  }
`
