import React from 'react'
import { styled } from '@linaria/react'
import type { IGatsbyImageData } from 'gatsby-plugin-image'
import { pxToRem } from '@/utils/unitsInRem'
import { Typography } from '@/UI'
import { FileNode, useGetImagesFromAssets } from '@/hooks'
import { SectionContainer } from '../../components'
import { screensTexts } from '../../_DATA'
import { FinopsCarousel } from './FinopsCarousel'

interface Props {}

export const Screens = ({}: Props) => {
  const images = useGetImagesFromAssets({ folder: `services/azure/${screensTexts.imageFolder}` })
  const imagesFinal = prepareImages(images)

  return (
    <SectionContainer
      title={screensTexts.title}
      subTitle={screensTexts.subtitle}
      appearance="dark"
      subtitleColor="gray"
      headerContentGap={pxToRem(64)}
    >
      <FinopsCarousel images={imagesFinal} />
      <Text dangerouslySetInnerHTML={{ __html: screensTexts.text }} $bodyVariant="small" $isInverse />
    </SectionContainer>
  )
}

function prepareImages(input: FileNode[]): IGatsbyImageData[] {
  const sorted = [...input].sort((a, b) => Number(a.name) - Number(b.name))
  return sorted.map(({ childImageSharp }) => childImageSharp.gatsbyImageData)
}

const Text = styled(Typography)`
  align-self: center;
  max-width: ${pxToRem(776)};
  text-align: center;
`
