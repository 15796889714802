import { styled } from '@linaria/react'

export const OpacityCommon = styled.div<{ $isVisible: boolean }>`
  transition-property: opacity;
  transition-timing-function: ease;
  overflow: ${({ $isVisible }) => ($isVisible ? 'auto' : 'hidden')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'all' : 'none')};
  transition-duration: ${({ $isVisible }) => ($isVisible ? '300ms' : '0ms')};
`
