import GATSBY_COMPILED_MDX from "/Users/sasha/Projects/samexpert-com/content/posts/services/finops-azure-cost-optimisation.mdx";
import React from 'react';
import {graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import {MDXProvider} from '@mdx-js/react';
import {Layout, SEO} from '@/blocks/layout';
const ServiceNewTemplate = ({data, pageContext, children}) => {
  return React.createElement(Layout, {
    breadcrumbsLink: "/",
    breadcrumbsText: "home",
    hasNoVerticalMargins: true
  }, React.createElement(MDXProvider, null, children));
};
ServiceNewTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ServiceNewTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data, pageContext}) => {
  const {title, description} = pageContext;
  const seoImage = getImage(data.seoImage.image.fixed).images.fallback.src;
  return React.createElement(SEO, {
    title: title,
    description: description,
    image: seoImage
  });
};
export const pageQuery = graphql`
  query ServiceNewQuery {
    seoImage: contentfulDefaultImage(for: { eq: "SEOteam" }) {
      image {
        fixed: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
          layout: FIXED
          formats: [AUTO]
          quality: 10
        )
      }
    }
  }
`;
