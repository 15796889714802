import React from 'react'
import { IconButtonCommon } from '@/UI'
import { SearchIcon } from '../common'
import { showSearch } from './state'

export const ShowSearchButton = () => {
  return (
    <IconButtonCommon onClick={showSearch} title="Search" aria-label="Toggle Search">
      <SearchIcon size={20} fill="#fff" />
    </IconButtonCommon>
  )
}
