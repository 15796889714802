import React from 'react'
import { useSnapshot } from 'valtio'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { MacScrollbar } from 'mac-scrollbar'
import { desktopSmallMedia } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { PortalWrapper, Wrapper } from '@/UI'
import { HeaderSectionsItemsWrapper, HeaderMenuSection } from '@/blocks/layout/partials/header/components'
import { RootMenuItemsDesktop } from './RootMenuItemsDesktop'
import { menuSectionsToDisplay } from '../presets'
import { useGetMenuItems } from '../hooks'
import { removeActiveNavSection, navSectionsState } from '../state'
import { OpacityCommon } from './styles'

export const NavigationDesktop = () => {
  const menuSections = useGetMenuItems()
  const { activeSection } = useSnapshot(navSectionsState)

  return (
    <NavigationDesktopView onMouseLeave={removeActiveNavSection}>
      <RootMenuItemsDesktop menuSections={menuSectionsToDisplay} />
      <PortalWrapper
        closeHandler={removeActiveNavSection}
        isVisible={Boolean(activeSection)}
        offsetTop="var(--header-h)"
        renderInDOM
        role="navigation"
      >
        <WrapperStyled>
          <RelativeWrapper>
            {menuSectionsToDisplay.map(menuSection => (
              <NavSectionView $isVisible={activeSection === menuSection}>
                <MacScrollbar
                  skin="dark"
                  className={css`
                    width: 100%;
                  `}
                >
                  <HeaderSectionsItemsWrapper as="nav">
                    {menuSections[menuSection].map(({ groupName, groupItems }) => (
                      <HeaderMenuSection title={groupName} data={groupItems} onClick={removeActiveNavSection} />
                    ))}
                  </HeaderSectionsItemsWrapper>
                </MacScrollbar>
              </NavSectionView>
            ))}
          </RelativeWrapper>
        </WrapperStyled>
      </PortalWrapper>
    </NavigationDesktopView>
  )
}

const NavigationDesktopView = styled.div`
  display: none;
  height: 100%;
  margin-right: 3rem;

  ${desktopSmallMedia} {
    display: flex;
    align-items: center;
  }
`

const WrapperStyled = styled(Wrapper)`
  padding-top: ${pxToRem(72)};
  padding-bottom: ${pxToRem(72)};
  max-height: 100%;
  flex-grow: 1;
`

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const NavSectionView = styled(OpacityCommon)`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
`
