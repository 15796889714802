import React, { useState } from 'react'
import { styled } from '@linaria/react'
import { makeTextBaseStyles } from '@/UI'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'
import { TransitionDropdown } from '@/UI/TransitionDropdown'
import { pxToRem } from '@/utils/unitsInRem'
import { AccordionSectionContentType } from './types'
import { Indicator } from './Indicator'
import { SmallTitle } from '../styles'

export const AccordionSection = ({ title, text }: AccordionSectionContentType) => {
  const [isOpened, setIsOpened] = useState(false)
  const toggleOpen = () => setIsOpened(oldState => !oldState)

  return (
    <AccordionSectionView onClick={toggleOpen}>
      <TitleSection>
        <Indicator isOn={isOpened} />
        <SmallTitle $isNotFat>{title}</SmallTitle>
      </TitleSection>
      <TransitionDropdown isVisible={isOpened} duration={0.3} timeout={300}>
        <Text>{text}</Text>
      </TransitionDropdown>
    </AccordionSectionView>
  )
}

const AccordionSectionView = styled.div`
  display: grid;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: ${colors.background.grayLightest};
  color: ${colors.textStyles.regular};
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;

  ${media.hover} {
    &:hover {
      background-color: ${colors.primary.lightest};

      & svg {
        transition: stroke 0.3s ease-in-out;
        stroke: ${colors.primary.primary};
      }
    }
  }
  &:active {
    background-color: ${colors.primary.lightest};

    & svg {
      transition: stroke 0.3s ease-in-out;
      stroke: ${colors.primary.primary};
    }
  }
`

const TitleSection = styled.div`
  font-family: ${fontLeagueSpartan};
  padding-top: ${pxToRem(4)};
  padding-left: 2rem;
  position: relative;
`

const Text = styled.div`
  padding-left: 2rem;
  padding-top: 1rem;
  ${makeTextBaseStyles({ variant: 'small' })}
`
