import React from 'react'
import { css } from '@linaria/core'
import { useSnapshot } from 'valtio'
import { styled } from '@linaria/react'
import { Chevron, CloseIcon, IconButtonCommon, TransitionFade } from '@/UI'
import { MenuListMob } from './MenuListMob'
import { navStateMobile, navSectionsState, removeActiveNavSection } from '../state'
import { PortalWrapperHeaderContent } from '../../common'
import { useCloseNavHandler } from '../hooks'

export const NavigationMobile = () => {
  const { isNavVisible } = useSnapshot(navStateMobile)
  const { activeSection } = useSnapshot(navSectionsState)
  const closeNav = useCloseNavHandler()

  return (
    <PortalWrapperHeaderContent closeHandler={closeNav} isVisible={isNavVisible} renderInDOM>
      <HeaderBlock>
        <TransitionFade isVisible={Boolean(activeSection)}>
          <IconButtonCommon onClick={removeActiveNavSection}>
            <Chevron direction="left" />
          </IconButtonCommon>
        </TransitionFade>
        <IconButtonCommon
          onClick={closeNav}
          className={css`
            margin-left: auto;
          `}
        >
          <CloseIcon />
        </IconButtonCommon>
      </HeaderBlock>
      <MenuListMob />
    </PortalWrapperHeaderContent>
  )
}

const HeaderBlock = styled.div`
  width: 100%;
  height: var(--header-h);
  display: flex;
  align-items: center;
`
