import React from 'react'
import { styled } from '@linaria/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Scrollbar } from 'swiper/modules'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { ArrowButton } from './ArrowButton'
import { CustomScrollbar } from './CustomScrollbar'
import { AspectRatioContainer } from '@/UI'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import { media } from '@/lib/theme'

interface Props {
  images: IGatsbyImageData[]
}

export const FinopsCarousel = ({ images }: Props) => {
  return (
    <FinopsCarouselWrapper>
      <ArrowButton className="custom-prev" rotate aria-label="Previous slide" />
      <Swiper
        modules={[Navigation, Scrollbar]}
        zoom={{ maxRatio: 15 }}
        spaceBetween={16}
        loop
        navigation={{
          prevEl: '.custom-prev',
          nextEl: '.custom-next',
        }}
        scrollbar={{
          draggable: false,
          el: '.my-swiper-scrollbar',
          dragClass: 'my-swiper-scrollbar-drag',
        }}
        slidesPerView={1}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {images.map((image, idx) => (
          <SwiperSlide key={idx}>
            <Slide verticalRatio="56%">
              <GatsbyImage alt="" image={image} />
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>
      <ArrowButton className="custom-next" aria-label="Next slide" />
      <CustomScrollbar className="my-swiper-scrollbar" />
    </FinopsCarouselWrapper>
  )
}

const FinopsCarouselWrapper = styled.div`
  position: relative;
  margin-bottom: 4.5rem;

  .custom-prev {
    left: -4rem;
  }

  .custom-next {
    right: -4rem;
  }
  
  ${media.desktopSmall} {
  margin-bottom: 7rem;
  }
`

const Slide = styled(AspectRatioContainer)`
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
`
//height: 238px;
