import React from 'react'
import { styled } from '@linaria/react'
import { pxToRem } from '@/utils/unitsInRem'
import { colors, media } from '@/lib/theme'
import { TitleBlock } from '../../../components'
import {} from '../../../_DATA'
import { Typography } from '@/UI'

interface Props {}

export const Tagline = ({ title, subTitle, text }: { title: string; subTitle: string; text: string }) => {
  return (
    <TaglineView>
      <TitleBlock alignment="start" subtitleColor="gray" title={title} subTitle={subTitle} />
      <TextBlock $bodyVariant="normal" dangerouslySetInnerHTML={{ __html: text }} />
    </TaglineView>
  )
}

const TaglineView = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  grid-column-gap: ${pxToRem(80)};
  margin-bottom: 3rem;
  align-items: center;

  ${media.desktopSmall} {
    padding: ${pxToRem(0, 80)};
    grid-template-columns: 38% auto;
    margin-bottom: ${pxToRem(80)};
  }
`

const TextBlock = styled(Typography)`
  ${media.desktopSmall} {
    padding: ${pxToRem(24, 0, 24, 32)};
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background: linear-gradient(
        to bottom,
        transparent,
        ${colors.primary.lightest} 10%,
        ${colors.primary.lightest} 90%,
        transparent
      );
    }
  }
`
