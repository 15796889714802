import React, { ReactNode } from 'react'
import { useLocation } from '@reach/router'
import theme from '~/gatsby-plugin-theme-ui'
import { useGetSiteMetadata } from '@/hooks'

interface SeoProps {
  description?: string
  title: string
  image?: string
  richresult?: string
  children?: ReactNode
}

export const SEO = ({ title, description, image, richresult, children }: SeoProps) => {
  const siteMetadata = useGetSiteMetadata()

  const { pathname } = useLocation()
  const metaDescription = description || siteMetadata.description
  const canonical = `${siteMetadata.siteUrl}${pathname.endsWith('/') ? pathname : `${pathname}/`}`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={canonical} />
      <meta name="theme-color" content={theme.colors.navback} />
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {Boolean(image) && <meta property="og:image" content={image} />}
      {Boolean(image) && <meta name="twitter:card" content="summary_large_image" />}
      {Boolean(richresult) && <script type="application/ld+json">{richresult}</script>}
      {children}
    </>
  )
}
