import React, { useState, useRef, ReactNode, useCallback, useEffect } from 'react'
import { styled } from '@linaria/react'
import { Stage, useTransition } from 'transition-hook'

interface TransitionDropdownProps {
  isVisible?: boolean
  children: ReactNode
  timeout?: number
  duration?: number
}

export function TransitionDropdown({ isVisible, children, timeout = 500, duration = 0.5 }: TransitionDropdownProps) {
  const { stage, shouldMount } = useTransition(Boolean(isVisible), timeout)
  const [detailsHeight, setDetailsHeight] = useState('0')
  const wrapperRef = useRef<HTMLDivElement>(null)

  const calculateHeight = useCallback(() => {
    if (!wrapperRef.current || !wrapperRef.current.children[0]) return
    const { height } = getComputedStyle(wrapperRef.current.children[0])
    setDetailsHeight(height)
  }, [])

  useEffect(() => {
    calculateHeight()
  }, [calculateHeight, stage])

  return (
    shouldMount && (
      <EffectWrapper ref={wrapperRef} stage={stage} duration={duration} nodeHeight={`${detailsHeight}`}>
        {children}
      </EffectWrapper>
    )
  )
}

const EffectWrapper = styled.div<{ stage: Stage; duration: number; nodeHeight: string }>`
  transition-timing-function: ease-in-out;
  transition-duration: ${({ duration }) => duration}s;
  height: ${({ stage, nodeHeight }) => getHeightFromTransition(stage, nodeHeight)};
  opacity: ${({ stage }) => getOpacityFromTransition(stage)};
`

function getHeightFromTransition(stage: Stage, nodeHeight: string): string {
  if (stage === 'from') return '0'
  if (stage === 'enter') return nodeHeight
  if (stage === 'leave') return '0'

  return '0'
}

function getOpacityFromTransition(stage: Stage): number {
  if (stage === 'from') return 0
  if (stage === 'enter') return 1
  if (stage === 'leave') return 0

  return 0
}
