import React from 'react'
import { styled } from '@linaria/react'
// import { Fade } from 'react-awesome-reveal'
import { InputStatusIcon } from '../InputStatusIcon'
import { colors } from '@/lib/theme'
import type { AppearanceType } from '@/blocks/contact-form/FormContent/types'
import { TransitionFade } from '@/UI'

interface CheckboxProps {
  isChecked?: boolean
  className?: string
  appearance: AppearanceType
  isPrimary: boolean
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
}

export function Checkbox({ isChecked, onChange, className, isPrimary, appearance }: CheckboxProps) {
  return (
    <Envelope className={className}>
      <HiddenCheckbox type="checkbox" checked={isChecked} onChange={onChange} />
      <CheckboxView $isChecked={isChecked} $isPrimary={isPrimary} $appearance={appearance}>
        <TransitionFade isVisible={isChecked} duration={200}>
          <InputStatusIcon status="isOk" />
        </TransitionFade>
      </CheckboxView>
    </Envelope>
  )
}

const Envelope = styled.span`
  width: 24px;
  height: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HiddenCheckbox = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  clip: rect(0 0 0 0);
`

const CheckboxView = styled.span<{ $isChecked: boolean; $appearance: AppearanceType; $isPrimary: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  border-width: 2px;
  border-radius: 4px;
  border-style: solid;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: border-color, background-color;
  color: ${({ $isChecked, $isPrimary, $appearance }) => getColor($isChecked, $appearance, $isPrimary)};
  border-color: currentColor;
`

function getColor(isChecked: boolean, appearance: AppearanceType, isPrimary: boolean) {
  switch (true) {
    case Boolean(isChecked):
      return colors.outcome.positive.positive
    case isPrimary:
      return colors.primary.primary
    default:
      return appearance === 'dark' ? colors.white : colors.secondary.secondary
  }
}
