import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@linaria/core'

export const Award = () => {
  return (
    <Container>
      {/*<Link className={linkStyles} to="/alexander-golev-consultant-of-the-year-2021-itam-review-excellence-awards/">*/}
      {/*  <StaticImage*/}
      {/*    src="/itamreviewbadge.png"*/}
      {/*    alt="Consultant of the Year Award"*/}
      {/*    placeholder="blurred"*/}
      {/*    width={150}*/}
      {/*    height={150}*/}
      {/*    imgStyle={{*/}
      {/*      height: '100%',*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Link>*/}
    </Container>
  )
}

const Container = styled.div`
  grid-area: extra;
  width: 150px;
  height: 150px;
  display: flex;
`

const linkStyles = css`
  width: 150px;
  height: 150px;
`
