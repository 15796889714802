import { css } from '@linaria/core'
import { fontInfer, media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

export const globals = css`
  :global() {
    html,
    body,
    p,
    ol,
    ul,
    li,
    dl,
    dt,
    dd,
    blockquote,
    figure,
    fieldset,
    legend,
    textarea,
    pre,
    iframe,
    hr,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 100%;
      font-weight: normal;
    }
    ul {
      list-style: none;
    }
    button,
    input,
    select {
      margin: 0;
    }
    html {
      box-sizing: border-box;
    }
    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }
    img,
    video {
      height: auto;
      max-width: 100%;
    }
    iframe {
      border: 0;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    td,
    th {
      padding: 0;
    }

    input[type='submit'],
    input[type='reset'],
    input[type='button'],
    button {
      background: none;
      border: 0;
      color: inherit;
      font: inherit;
      line-height: normal;
      overflow: visible;
      padding: 0;
      -webkit-appearance: none;
      -webkit-user-select: none;
      appearance: none;
      -moz-user-select: none;
      -ms-user-select: none;
      box-sizing: border-box;
    }

    input::-moz-focus-inner,
    button::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    textarea {
      font-family: inherit;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: unset;
      padding: 0;
      font: inherit;
    }

    fieldset {
      margin: 0;
      border: unset;
    }

    a {
      color: unset;
      text-decoration: none;
    }

    :focus {
      outline: unset;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    button {
      background: none;
      border: 0;
      color: inherit;
      font: inherit;
      line-height: normal;
      overflow: visible;
      padding: 0;
      -webkit-appearance: button;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    body {
      --header-h: ${pxToRem(56)};
      --breadcrumbs-h: ${pxToRem(48)};
      font-family: ${fontInfer};
      -webkit-tap-highlight-color: transparent;
      font-weight: 400;

      ${media.desktopSmall} {
        --header-h: ${pxToRem(80)};
      }
    }
  }
`
