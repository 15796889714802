// @ts-nocheck
/* eslint-disable */

import React from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { graphql, navigate } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Flickity from 'react-flickity-component'
import '../flickity/flickity.css'
import { RichSnippet, RichCase } from '../components/richtext'
import { tabletMedia, desktopSmallMedia, desktopLargeMedia, fontLibreBaskerville, media } from '@/lib/theme'
import SectionWithHighlight from '~/components/service/sectionwithhighlight'
import Highlight from '~/components/service/highlight'
import Steps from '~/components/service/steps'
import { SEO, Layout } from '@/blocks/layout'
import { pxToRem } from '@/utils/unitsInRem'
import { ContactFormWithWrapper, SideBlock } from '@/blocks/contact-form'
import { Button, makeH3Styles, TitleH2, TitleMain, Wrapper } from '@/UI'
import { AnnouncesGrid } from '@/blocks/announces'
import { LogoScroller } from '@/blocks/LogoScroller'

const TestimonialsWrapper = styled.div`
  display: grid;
  align-items: center;
  background: #ededed;
  padding: 24px var(--layout-indent) 48px var(--layout-indent);

  ${tabletMedia} {
    padding: 48px;
  }
`

const OneTestimonial = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 120px;
  margin-right: 24px;
  display: grid;
  grid-template: 'name' 'company' 'text';
  justify-items: start;
  ${desktopSmallMedia} {
    grid-template: 'name company' 'text text';
    grid-template-columns: min-content auto;
  }
`

const TestimonialName = styled.div`
  grid-area: name;
  padding: 3px 16px;
  background: #272424;
  opacity: 0.6;
  border-radius: 0px;
  font-weight: 700;
  font-size: ${pxToRem(12)};
  line-height: 160%;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
  white-space: nowrap;
`

const TestimonialCompany = styled.div`
  grid-area: company;
  padding: 3px 16px;
  background: black;
  border-radius: 0px;
  font-weight: 700;
  font-size: ${pxToRem(12)};
  line-height: 160%;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #ffffff;
`

const TestimonialText = styled.div`
  grid-area: text;
  padding: 36px 24px 0 24px;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: 150%;
  position: relative;
  width: 100%;
  ::before {
    left: 48px;
    position: absolute;
    content: '“';
    transform: translate(-32px, -8px);
    opacity: 0.6;
    font-size: ${pxToRem(80)};
  }
  ${desktopSmallMedia} {
    padding: 24px 24px 24px 40px;
    ::before {
      transform: translate(-52px, 16px);
    }
  }
`

const Testimonials = ({ testimonials }) => {
  return (
    <Wrapper style={{ gridArea: 'testimonials', marginBottom: 'var(--content-blocks-gap)' }} $noMobilePadding>
      <TestimonialsWrapper>
        <Flickity
          options={{
            prevNextButtons: false,
            wrapAround: true,
            autoPlay: 14000,
          }}
        >
          {testimonials.map(item => {
            return (
              <OneTestimonial key={item.contentful_id}>
                <TestimonialName>{item.name}</TestimonialName>
                <TestimonialCompany>{item.company}</TestimonialCompany>
                <TestimonialText>{item.text.text}</TestimonialText>
              </OneTestimonial>
            )
          })}
        </Flickity>
      </TestimonialsWrapper>
    </Wrapper>
  )
}

const PageTemplate = ({ data }) => {
  const service = data.contentfulService

  return (
    <Layout>
      <PageWrapper>
        <TitleBlock>
          <TitleMain $variant="XXL">{service.title}</TitleMain>
          <Button desktopWidth="100%" onClick={() => navigate('#contactform')}>
            Contact us
          </Button>
        </TitleBlock>
        <ProblemsAndSolutions>
          <Problems>
            <InnerContainer>
              <TitleH2 $gap={0}>{service.problemsHeading}</TitleH2>
              <RichSnippet content={service.problemsBody} />
            </InnerContainer>
          </Problems>
          <Solutions>
            <InnerContainer>
              <TitleH2 $gap={0}>{service.solutionHeading}</TitleH2>
              <RichSnippet content={service.solutionBody} />
            </InnerContainer>
          </Solutions>
        </ProblemsAndSolutions>
        <SectionWithHighlight style={{ gridArea: 'howwework' }}>
          <TitleH2 $gap={0} style={{ gridArea: 'heading' }}>
            {service.howWeWorkHeading}
          </TitleH2>
          <RichSnippet style={{ gridArea: 'body' }} content={service.howWeWorkBody} />
          <Highlight style={{ gridArea: 'highlight' }}>{service.howWeWorkHighlight}</Highlight>
        </SectionWithHighlight>
        {service.steps && <Steps steps={service.steps} />}
        {service.whyWorkWithUs && <USPs usps={service.whyWorkWithUs} />}
        <SectionWithHighlight style={{ gridArea: 'whyworkwithus' }}>
          <TitleH2 $gap={0} style={{ gridArea: 'heading' }}>
            {service.whyWorkWithUsHeading}
          </TitleH2>
          <RichSnippet style={{ gridArea: 'body' }} content={service.whyWorkWithUsBody} />
          <Highlight style={{ gridArea: 'highlight' }}>{service.whyWorkWithUsHighlight}</Highlight>
        </SectionWithHighlight>
        <FormStyled title={service.contactUsHeading} id="contactform">
          <SideBlock />
        </FormStyled>
        {service.testimonials && <Testimonials testimonials={service.testimonials} />}
        {service.logos && <Logos logos={service.logos} />}
        {service.cases && (
          <Wrapper>
            <TitleH2 $gap={32}>{service.casesHeading}</TitleH2>
            <Cases cases={service.cases} />
          </Wrapper>
        )}
      </PageWrapper>
    </Layout>
  )
}

const Logos = styled(LogoScroller)`
  grid-area: clients;
  margin-bottom: var(--content-blocks-gap);
`

const TitleH3 = styled.h3`
  ${makeH3Styles({})};
`

const TitleBlock = styled(Wrapper)`
  grid-area: hero;
  display: grid;
  grid-row-gap: 2rem;
  margin-bottom: var(--content-blocks-gap);

  ${media.desktopSmall} {
    grid-template-columns: auto 160px;
    align-items: center;
  }
`

const FormStyled = styled(ContactFormWithWrapper)`
  margin-bottom: var(--content-blocks-gap);
`

const SingleCase = styled.div`
  background: #dbd8d4;
  padding: 24px;
  white-space: normal;
`

const CaseTitle = styled.h3`
  ${makeH3Styles({})}
  padding-bottom: 24px;
`

const caseclass = css`
  h4 {
    ${makeH3Styles({})}
    font-size: ${pxToRem(14)} !important;
    text-transform: uppercase;
    padding-bottom: 8px;
    ::after {
      content: ':';
    }
    :not(:first-of-type) {
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px dashed rgba(0, 0, 0, 0.4);
      ${desktopLargeMedia} {
        margin-top: -8px;
      }
    }
  }
  ul {
    margin-left: 16px;
    list-style-type: disc;
    li {
      margin: 0;
    }
  }
  ${desktopLargeMedia} {
    *:not(h4, li, li p) {
      transform: translateY(-32px);
      margin-left: 112px;
    }
    ul {
      padding-left: 16px;
    }
  }
`

const Cases = ({ cases }) => {
  return (
    <AnnouncesGrid $handlesTabletMq={cases.length}>
      {cases.map(item => {
        return (
          <SingleCase key={item.contentful_id}>
            <CaseTitle>{item.title}</CaseTitle>
            <RichCase content={item.content} className={caseclass} />
          </SingleCase>
        )
      })}
    </AnnouncesGrid>
  )
}

const USPGrid = styled(Wrapper)`
  grid-area: usps;
  display: grid;
  width: 100%;
  grid-template-rows: repeat(${props => props.usps}, 1fr);
  grid-template-columns: 1fr;
  padding-bottom: var(--content-blocks-gap);
  ${desktopSmallMedia} {
    grid-template-columns: repeat(${props => props.usps}, 1fr);
    grid-template-rows: 1fr;
  }
  ${desktopLargeMedia} {
    width: 100%;
    justify-self: center;
  }
`

const SingleUSP = styled.div`
  display: grid;
  grid-template: 'title' 'content';
  grid-template-rows: max-content auto;
  padding: 24px;
  gap: 24px;
  background-color: #ededed;
  :nth-child(even) {
    background-color: #e3e3e3;
  }
  ${desktopSmallMedia} {
    padding: 48px;
  }
`

const USPs = ({ usps }) => {
  return (
    <USPGrid usps={usps.length}>
      {usps.map(item => {
        return (
          <SingleUSP key={item.contentful_id}>
            <TitleH3 style={{ gridArea: 'title' }}>{item.title}</TitleH3>
            <RichSnippet style={{ gridArea: 'content' }} content={item.content} />
          </SingleUSP>
        )
      })}
    </USPGrid>
  )
}

export default PageTemplate

export const Head = ({ data }) => {
  const service = data.contentfulService
  const seoImageExists = !!service.seoImage
  const seoImage = seoImageExists ? getImage(service.seoImage.fixed).images.fallback.src : undefined

  return <SEO title={service.title} description={service.seoDescription} image={seoImage} />
}

export const pageQuery = graphql`
  query ContentfulServiceBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulService(serviceId: { eq: $slug }) {
      title: serviceName
      seoDescription
      problemsHeading
      problemsBody {
        raw
      }
      solutionHeading
      solutionBody {
        raw
      }
      howWeWorkHeading
      howWeWorkBody {
        raw
        references {
          ... on ContentfulAsset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 20)
            contentful_id
            title
          }
        }
      }
      howWeWorkHighlight
      steps {
        ... on ContentfulServiceStep {
          name
          subtitle
          substeps
          contentful_id
        }
      }
      whyWorkWithUs {
        contentful_id
        title
        content {
          raw
        }
      }
      whyWorkWithUsHeading
      whyWorkWithUsBody {
        raw
      }
      whyWorkWithUsHighlight
      testimonials {
        name
        company
        shortTitle
        text {
          text
        }
        contentful_id
      }
      logos {
        id
        contentfulid
        okOnCompanyPage
        order
        logo {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            height: 90
            resizingBehavior: SCALE
            layout: FIXED
          )
        }
      }
      casesHeading
      cases {
        title
        content {
          raw
        }
        contentful_id
      }
      seoImage {
        fixed: gatsbyImageData(width: 1200, height: 630, layout: FIXED, formats: [AUTO], quality: 10)
      }
      contactUsHeading
    }
  }
`

const PageWrapper = styled.div`
  font-family: ${fontLibreBaskerville};
  display: grid;
  grid-template: 'hero' 'probsol' 'howwework' 'steps' 'usps' 'whyworkwithus' 'newForm' 'testimonials' 'clients' 'casesheading' 'cases';
`

const ProblemsAndSolutions = styled(Wrapper)`
  display: grid;
  grid-area: probsol;
  grid-template: 'problems' 'solutions';
  margin-bottom: var(--content-blocks-gap);
  ${desktopSmallMedia} {
    grid-template: 'problems solutions';
    grid-template-columns: auto auto;
  }
  ${desktopLargeMedia} {
    justify-self: center;
  }
`

const Problems = styled.div`
  background: #ededed;
  grid-area: problems;
  display: grid;
  align-items: start;
`

const Solutions = styled.div`
  background: #e3e3e3;
  grid-area: solutions;
  display: grid;
  align-items: start;
`

const InnerContainer = styled.div`
  display: grid;
  grid-template: 'header' 'contents';
  padding: 48px 24px;
  gap: 32px;
  ${tabletMedia} {
    padding: 48px;
  }
`
