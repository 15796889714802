import React from 'react'
import { styled } from '@linaria/react'
import { pxToRem } from '@/utils/unitsInRem'
import { processTexts } from '../../_DATA'
import { SectionContainer, SmallTitle } from '../../components'
import { ProcessIconsBlock } from './ProcessIconsBlock'
import { makeTextBaseStyles } from '@/UI'
import { colors, media } from '@/lib/theme'

interface Props {}

export const Process = ({}: Props) => {
  return (
    <SectionContainer
      title={processTexts.title}
      subTitle={processTexts.subTitle}
      appearance="dark"
      subtitleColor="inverse"
      headerContentGap={pxToRem(72)}
    >
      <ProcessGrid>
        {processTexts.elements.map(({ text, title }, idx) => (
          <Element key={title}>
            <ProcessIconsBlock index={idx} />
            <Texts>
              <SmallTitle $isInverse>{title}</SmallTitle>
              <Text>{text}</Text>
            </Texts>
          </Element>
        ))}
      </ProcessGrid>
    </SectionContainer>
  )
}

const ProcessGrid = styled.div`
  grid-row-gap: 2rem;
  grid-column-gap: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  ${media.desktopSmall} {
    grid-row-gap: 6rem;
  }
`

const Element = styled.div`
  display: grid;
  grid-row-gap: 2rem;
`

const Texts = styled.div`
  display: grid;
  grid-row-gap: 0.75rem;
  margin-left: 1.5rem;
  padding-left: 2rem;
  border-left: 1px dashed ${colors.primary.primary};
`

const Text = styled.span`
  ${makeTextBaseStyles({ variant: 'small' })};
`
