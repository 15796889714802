import { graphql, useStaticQuery } from 'gatsby'
import { MenuGroup, MenuItemRaw, MenuSectionsMap } from '../types'

interface Data {
  allContentfulService: {
    nodes: {
      group: string
      serviceName: string
      serviceId: string
      order: number
    }[]
  }
  allMdx: {
    nodes: {
      fields: {
        slug: string
      }
      frontmatter: {
        group: string
        title: string
        order: number
      }
    }[]
  }
}

export function useGetMenuItems(): MenuSectionsMap {
  const data = useStaticQuery<Data>(graphql`
    query headerMenuQuery {
      allContentfulService(filter: { enabled: { eq: true }, moreButtonEnabled: { eq: true } }, sort: [{ order: ASC }]) {
        nodes {
          group
          serviceName
          serviceId
          order
        }
      }
      allMdx(
        filter: { frontmatter: { pagetype: { eq: "service" } } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            title
            group
            order
          }
        }
      }
    }
  `)

  const nodesContentful: MenuItemRaw[] = data.allContentfulService.nodes.map(
    ({ group, serviceName, serviceId, order }) => ({
      group,
      name: serviceName,
      slug: `/services/${serviceId}/`,
      order,
    }),
  )

  const nodesMdx: MenuItemRaw[] = data.allMdx.nodes.map(({ fields, frontmatter }) => ({
    group: frontmatter.group,
    slug: fields.slug,
    name: frontmatter.title,
    order: frontmatter.order,
  }))

  const allServiceItemsRaw: MenuItemRaw[] = [...nodesContentful, ...nodesMdx]

  const serviceGroupNames = Array.from(new Set(allServiceItemsRaw.map(({ group }) => group)))

  const serviceGroups: MenuGroup[] = serviceGroupNames.map(group => {
    return {
      groupName: group,
      groupItems: allServiceItemsRaw
        .filter(item => item.group === group)
        .slice()
        .sort((a, b) => a.order - b.order)
        .map(({ name, slug }) => {
          return {
            title: name,
            url: slug,
          }
        }),
    }
  })

  const insightGroups: MenuGroup[] = [
    {
      groupItems: [
        {
          title: 'Articles and Guides',
          url: '/blog/',
        },
        {
          title: 'Videos',
          url: '/video/',
        },
        {
          title: 'Podcast',
          url: '/podcast/',
        },
      ],
    },
  ]

  return {
    Services: serviceGroups,
    Insights: insightGroups,
  }
}
