export const comparisonTexts = {
  title: 'FinOps tools and services comparison',
  text: 'Book a free tech demo today to explore its features and capabilities. See firsthand how it aligns with your cloud cost management needs and helps you achieve efficiency without commitments.',
  table: {
    rowsHeaders: ['Pricing & Revenue Model', 'Data Hosting & Security'],
    columns: [
      {
        header: 'Our solution',
        row1st: '<p>Gain-share or fixed-fee consulting, aligned with cost savings</p><p>Minimal hosting fee (<$1k/month for your tool)</p>',
        row2nd: '<p>Deployed in the client’s Azure tenant (KQL / MS Fabric)</p><p>Data never leaves client environment</p>',
      },
      {
        header: 'Cloudability',
        row1st: '<p>Often percentage of spend (“cost under management”)</p><p>Can become expensive at scale</p>',
        row2nd: '<p>SaaS model; cost and usage data stored in vendor’s environment</p>',
      },
      {
        header: 'CloudHealth',
        row1st: '<p>Typically subscription-based</p><p>Cost not tied to realized savings</p>',
        row2nd: '<p>Similar SaaS approach; data leaves client environment</p>',
      },
      {
        header: 'MS native',
        row1st: '<p>No extra license fee for core Azure Cost Management</p><p>FinOps Toolkit is free to deploy</p>',
        row2nd: '<p>Stays in Azure tenant, fully Microsoft-managed, integrates with RBAC</p>',
      },
    ],
  },
}
