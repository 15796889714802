import React from 'react'
import { styled } from '@linaria/react'
import { StepType, SingleStep } from './SingleStep'
import { pxToRem } from '@/utils/unitsInRem'
import { media } from '@/lib/theme'

interface Props {
  content: StepType[]
}

export const Steps = ({ content }: Props) => {
  return (
    <StepsView>
      {content.map((step, idx) => (
        <SingleStep key={step.index} step={step} isFirstStep={idx === 0} isLastStep={idx === content.length - 1} />
      ))}
    </StepsView>
  )
}

const StepsView = styled.div`
  display: grid;
  margin-bottom: ${pxToRem(48)};
  align-self: center;
  max-width: ${pxToRem(480)};

  ${media.desktopSmall} {
    max-width: unset;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: ${pxToRem(72)};
  }
`
