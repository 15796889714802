import React, { useEffect } from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { colors, fontLeagueSpartan } from '@/lib/theme'
import { Wrapper } from '@/UI'
import { topBlockShadow } from '@/blocks/layout/partials/common'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'
import {
  Logo,
  Search,
  BurgerButton,
  ContactMobButton,
  ShowSearchButton,
  NavigationMobile,
  NavigationDesktop,
  ButtonContactCommon,
  useCloseNavHandler,
  navStateMobile,
  navSectionsState,
} from './components'

interface Props {
  withShadow?: boolean
}

export const Header = ({ withShadow }: Props) => {
  const { isNavVisible } = useSnapshot(navStateMobile)
  const { activeSection } = useSnapshot(navSectionsState)
  const closeNav = useCloseNavHandler()

  useEffect(() => {
    if (!(isNavVisible || activeSection)) return
    window.addEventListener('resize', closeNav)

    return () => {
      window.removeEventListener('resize', closeNav)
    }
  }, [activeSection, closeNav, isNavVisible])

  return (
    <HeaderView $withShadow={withShadow}>
      <HeaderContentWrapper $noMobilePadding={false}>
        <Logo />
        <NavigationDesktop />
        <ButtonsBlock>
          <ShowSearchButton />
          <ButtonContactCommon showOn="desktop" />
          <ContactMobButton />
          <BurgerButton />
        </ButtonsBlock>
      </HeaderContentWrapper>
      <Search />
      <NavigationMobile />
    </HeaderView>
  )
}

const HeaderView = styled.header<{ $withShadow: boolean }>`
  font-family: ${fontLeagueSpartan};
  display: flex;
  width: 100%;
  background-color: ${colors.secondary.secondary};
  position: fixed;
  top: 0;
  z-index: 99;
  height: var(--header-h);
  box-shadow: ${({ $withShadow }) => ($withShadow ? topBlockShadow : 'none')};
  ${doNotPrintThisBlock};
`

const HeaderContentWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
`

const ButtonsBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  margin-left: auto;
`
