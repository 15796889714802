import React, { FC } from 'react'
import { styled } from '@linaria/react'
import { Styleable } from '@/lib/interfaces'
import { colors } from '@/lib/theme'

interface Props extends Styleable {}

export function CustomScrollbar({ className }: Props) {
  return <ScrollbarContainer className={className} />
}

const ScrollbarContainer = styled.div`
  position: absolute;
  bottom: -2rem;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  height: 8px;
  background: ${colors.white};
  border-radius: 4px;

  .my-swiper-scrollbar-drag {
    background: ${colors.secondary.secondary};
    border-radius: 4px;
    height: 4px;
    top: 2px;
    position: absolute;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -1rem;
    z-index: -1;
    width: calc(100% + 2rem);
    height: 100%;
    border-radius: 4px;
    background: ${colors.white};
  }
`
