import React from 'react'
import { css } from '@linaria/core'

interface Props {
  size?: number
  direction?: 'left' | 'right'
}

export const Chevron = ({ size = 32, direction = 'right' }: Props) => {
  return (
    <svg
      fill="currentColor"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={css`
        transform-origin: center;
      `}
      style={{ transform: `scaleX(${direction === 'right' ? 1 : `-1`})` }}
    >
      <path d="M11 8L6 13 5.3 12.3 9.6 8 5.3 3.7 6 3z" />
    </svg>
  )
}
