import React from 'react'
import { styled } from '@linaria/react'
import { makeTextBaseStyles, TitleMain } from '@/UI'
import { colors, media } from '@/lib/theme'
import { Styleable } from '@/lib/interfaces'

export type SubtitleColorType = 'normal' | 'inverse' | 'gray'
type TextAlignType = 'start' | 'center'

const subtitlePalette: Record<SubtitleColorType, string> = {
  normal: colors.textStyles.regular,
  inverse: colors.textStyles.inverse,
  gray: colors.tertiary.tertiary,
}

interface Props extends Styleable {
  title?: string
  subTitle?: string
  alignment?: TextAlignType
  marginBottom?: string
  titleIsInverse?: boolean
  subtitleColor?: SubtitleColorType
  titleTag?: 'h1' | 'h2'
}

export const TitleBlock = ({
  title,
  subTitle,
  className,
  marginBottom,
  titleIsInverse,
  subtitleColor,
  titleTag = 'h2',
  alignment = 'center',
}: Props) => {
  if (!title && !subTitle) return null

  return (
    <TitleBlockView $bottomMargin={marginBottom} $alignment={alignment} className={className}>
      {title && (
        <TitleMain as={titleTag} $isCentered={alignment === 'center'} $isInverse={titleIsInverse}>
          {title}
        </TitleMain>
      )}
      {subTitle && (
        <Subtitle $alignment={alignment} $color={subtitlePalette[subtitleColor]}>
          {subTitle}
        </Subtitle>
      )}
    </TitleBlockView>
  )
}

const TitleBlockView = styled.div<{ $bottomMargin: string; $alignment: TextAlignType }>`
  display: grid;
  grid-row-gap: 1.5rem;
  justify-items: ${({ $alignment }) => $alignment};
  margin-bottom: 2rem;

  ${media.desktopSmall} {
    margin-bottom: ${({ $bottomMargin }) => $bottomMargin};
  }
`

const Subtitle = styled.p<{ $color: string; $alignment: TextAlignType }>`
  ${makeTextBaseStyles({ variant: 'large' })};
  color: ${({ $color }) => $color};
  text-align: ${({ $alignment }) => $alignment};
`
