import React, { useEffect } from 'react'
import { useHits, useStats } from 'react-instantsearch'
import { useCloseSearchHandler } from '../../../hooks'
import { HeaderMenuItemDataType, HeaderMenuSection } from '../../common/HeaderMenuSection'
import { searchQueryHitsCount } from '../state'

interface Props {
  sectionName: string
  sectionTitle: string
}
export const CustomHitsSection = ({ sectionName, sectionTitle }: Props) => {
  const { hits } = useHits()
  const { nbHits } = useStats()
  const closeSearch = useCloseSearchHandler()

  useEffect(() => {
    searchQueryHitsCount.value[sectionName] = Boolean(nbHits)
  }, [nbHits, sectionName])

  if (!nbHits) return null

  return (
    <HeaderMenuSection title={sectionTitle} data={hits as unknown as HeaderMenuItemDataType[]} onClick={closeSearch} />
  )
}
