// custom typefaces
//import "typeface-montserrat"
//import "typeface-merriweather"

//import "prismjs/themes/prism.css"

import React from 'react'
import { Sprite } from './src/shared/lib/Sprite'

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Sprite />
      {element}
    </>
  )
}
