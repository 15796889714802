import React from 'react'
import { styled } from '@linaria/react'
import { pxToRem } from '@/utils/unitsInRem'
import { Wrapper } from '@/UI'
import { Styleable, WithChildren } from '@/lib/interfaces'
import { colors, media } from '@/lib/theme'
import { TitleBlock, SubtitleColorType } from './TitleBlock'

type SectionAppearanceType = 'light' | 'dark' | 'gray'

const sectionAppearance: Record<SectionAppearanceType, { backgroundColor: string; textColor: string }> = {
  light: {
    backgroundColor: colors.white,
    textColor: colors.textStyles.regular,
  },
  gray: {
    backgroundColor: colors.background.grayLightest,
    textColor: colors.textStyles.regular,
  },
  dark: {
    backgroundColor: colors.secondary.secondary,
    textColor: colors.white,
  },
}

interface Props extends WithChildren, Styleable {
  title?: string
  subTitle?: string
  appearance?: SectionAppearanceType
  headerContentGap?: string
  subtitleColor?: SubtitleColorType
  hasNoWrapper?: boolean
  contentIsCentered?: boolean
}

export const SectionContainer = ({
  children,
  title,
  subTitle,
  className,
  hasNoWrapper,
  contentIsCentered,
  appearance = 'light',
  subtitleColor = 'normal',
  headerContentGap = pxToRem(32),
}: Props) => {
  const content = (
    <>
      <TitleBlock
        title={title}
        subTitle={subTitle}
        subtitleColor={subtitleColor}
        marginBottom={headerContentGap}
        titleIsInverse={appearance === 'dark'}
      />
      {children}
    </>
  )
  return (
    <SectionContainerView $appearance={appearance} className={className}>
      {hasNoWrapper && !contentIsCentered && content}
      {hasNoWrapper && contentIsCentered && <CenteredBlock>{content}</CenteredBlock>}
      {!hasNoWrapper && (
        <Wrapper $displayProperty="flex" $flexDirectionProperty="column">
          {content}
        </Wrapper>
      )}
    </SectionContainerView>
  )
}

const SectionContainerView = styled.div<{ $appearance: SectionAppearanceType }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ $appearance }) => sectionAppearance[$appearance].backgroundColor};
  color: ${({ $appearance }) => sectionAppearance[$appearance].textColor};
  padding: 2rem 0;

  ${media.desktopSmall} {
    padding: ${pxToRem(72)} 0;
  }
`

const CenteredBlock = styled.div`
  margin: 0 auto;
`
