import { css } from '@linaria/core'
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState, MouseEvent } from 'react'
import { styled } from '@linaria/react'
import { useSearchBox } from 'react-instantsearch'
import { useSnapshot } from 'valtio'
import { colors, desktopSmallMedia, fontLeagueSpartan } from '@/lib/theme'
import { useDebounce } from '@/hooks'
import { pxToRem } from '@/utils/unitsInRem'
import { CloseIcon, IconButtonCommon } from '@/UI'
import { SearchIcon } from '../../common'
import { setSearchQuery, searchQueryState } from '../state'
import { useCloseSearchHandler } from '../../../hooks'
import { SearchBoxCommonStyles } from './SearchBoxCommonStyles'

interface Props {
  onFocus?: () => void
}

export const SearchBox = ({ onFocus }: Props) => {
  const state = useSnapshot(searchQueryState)
  const inputRef = useRef<HTMLInputElement>(null)
  const { query, refine } = useSearchBox()
  const [inputQuery, setInputQuery] = useState(query)
  const closeSearchHandler = useCloseSearchHandler()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputQuery(e.target.value)
  }

  useDebounce(() => setSearchQuery(inputQuery), 500, [inputQuery])
  useEffect(() => refine(state.query), [refine, state.query])

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (inputRef.current) inputRef.current.blur()
  }

  const clickHandler = (e: MouseEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Container className={AnimationStyles}>
      <Form onSubmit={handleSubmit} className={SearchBoxCommonStyles}>
        <SearchIcon size={20} />
        <Input
          ref={inputRef}
          className="SearchInput"
          type="text"
          placeholder="Search"
          aria-label="Search"
          onClick={clickHandler}
          onChange={handleChange}
          value={inputQuery}
          onFocus={onFocus}
        />
      </Form>
      <CloseButton onClick={closeSearchHandler}>
        <CloseIcon />
      </CloseButton>
    </Container>
  )
}

const AnimationStyles = css`
  animation: wideness-fade 0.3s ease-in-out;

  @keyframes wideness-fade {
    from {
      flex-grow: 0;
      opacity: 0;
    }
    to {
      opacity: 1;
      flex-grow: 1;
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  opacity: 1;

  ${desktopSmallMedia} {
    margin-right: 32px;
  }
`

const Form = styled.form`
  background-color: ${colors.white};
  width: 32px;
  overflow: hidden;
  flex-grow: 1;
  

  ${desktopSmallMedia} {
    width: 264px;
  }
`

const Input = styled.input`
  margin-left: 8px;
  flex-grow: 1;
  font-family: ${fontLeagueSpartan};
  font-size: ${pxToRem(18)};
  padding-top: ${pxToRem(3)};
  
  &::placeholder {
    font-family: ${fontLeagueSpartan};
    font-size: ${pxToRem(18)};
  }
`

const CloseButton = styled(IconButtonCommon)`
  color: ${colors.white};
  margin-left: ${pxToRem(12)};
`
