import React from 'react'
import { styled } from '@linaria/react'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { siteUrl } from '_/config/site-config'
import { colors, fontLeagueSpartan, fontLibreBaskerville, media } from '@/lib/theme'
import { Button, IconSocial, textLinkStyles } from '@/UI'
import { useBookACall } from '@/hooks'
import { doNotPrintThisBlock } from '@/lib/sharedStyles'
import { Styleable } from '@/lib/interfaces'
import { pxToRem } from '@/utils/unitsInRem'
import avatar from '~/components/avatar'
import { CSSProperties } from '@linaria/core'

export interface Author {
  name: string
  slug: string
  linkedIn?: string | null
  role: string | null
  bookACall?: string | null
  bio: {
    bio: string
  }
  avatar?: {
    gatsbyImageData: IGatsbyImageData
  } | null
}

interface Props extends Styleable {
  author: Author
  isExternalPerson?: boolean
}

export const AuthorCard = ({ author, className, isExternalPerson }: Props) => {
  const bookACallHandler = useBookACall(author.bookACall)
  const linkFinal = !isExternalPerson && author.slug ? `${siteUrl}/team/${author.slug}/` : author.slug

  return (
    <AuthorCardView className={className}>
      {avatar && (
        <ImageBlock>
          {author.avatar && <GatsbyImage image={author.avatar.gatsbyImageData} alt={author.name} />}
        </ImageBlock>
      )}
      <Content>
        {linkFinal ? (
          <Link to={linkFinal}>
            <NameLink>{author.name}</NameLink>
          </Link>
        ) : (
          <NamePlain>{author.name}</NamePlain>
        )}
        {author.role && <Role>{author.role}</Role>}
        <Bio>{author.bio.bio}</Bio>
        <InteractiveContent>
          {!isExternalPerson && (
            <ButtonsBlock>
              <Link to={`${siteUrl}/contact/`}>
                <Button preventDefaultClick={false} type="secondary">
                  Contact
                </Button>
              </Link>
              <Button onClick={bookACallHandler} type="secondary">
                Book a call
              </Button>
            </ButtonsBlock>
          )}
          <SocialBlock>
            {author.linkedIn && (
              <IconSocial
                type="LinkedIn"
                urlAdd={`in/${author.linkedIn}`}
                size={24}
                fillBg={colors.secondary.secondary}
              />
            )}
          </SocialBlock>
        </InteractiveContent>
      </Content>
    </AuthorCardView>
  )
}

const AuthorCardView = styled.div`
  display: grid;
  background-color: ${colors.background.grayLightest};
  color: ${colors.secondary.darkest};
  border-radius: 16px;
  overflow: hidden;
  min-height: 277px;
  max-width: 392px;

  ${media.desktopSmall} {
    max-width: unset;
    grid-template-columns: 196px auto;
  }

  ${media.print} {
    min-height: unset;
  }
`

const ImageBlock = styled.div`
  display: flex;
  width: 100%;
  height: 250px;

  ${media.desktopSmall} {
    max-width: 196px;
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${media.mobileModern} {
    padding: 24px;
  }

  ${media.desktopSmall} {
    padding: 32px;
  }
`

const nameCommon: CSSProperties = {
  fontFamily: fontLeagueSpartan,
  fontSize: pxToRem(24),
  fontWeight: 700,
  lineHeight: '110%',
  letterSpacing: '-0.01em',
}

const NameLink = styled.span`
  ${nameCommon};
  ${textLinkStyles};
`

const NamePlain = styled.span`
  ${nameCommon};
`

const Role = styled.span`
  font-family: ${fontLibreBaskerville};
  font-size: ${pxToRem(16)};
  font-style: italic;
  font-weight: 400;
  line-height: 163%; /* 26.08px */
  margin-top: 4px;
  opacity: 0.5;
`

const Bio = styled.div`
  font-family: ${fontLibreBaskerville};
  font-size: ${pxToRem(14)};
  font-weight: 400;
  line-height: 163%;
  margin-top: 16px;

  ${media.desktopSmall} {
    margin-bottom: auto;
  }
`

const InteractiveContent = styled.div`
  display: grid;
  grid-gap: 24px;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  ${doNotPrintThisBlock};

  ${media.mobileModern} {
    margin-top: 24px;
  }

  ${media.desktopSmall} {
    justify-content: start;
    grid-auto-flow: column;
  }
`

const ButtonsBlock = styled.div`
  display: grid;
  grid-row-gap: 16px;

  ${media.desktopSmall} {
    grid-column-gap: 10px;
    grid-auto-flow: column;
  }
`

const SocialBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-self: start;
  align-items: center;
`
