export const expertTexts = {
  title: 'Expert team',
  subtitle: 'Meet our team: Certified and experienced professionals.',
  experts: [
    {
      name: 'Stefan Denk',
      slug: '',
      role: 'Practice lead',
      bio: {
        bio: 'Principal Azure Architect at Nestlé driving POC to Public Cloud. Built Swisscom FinOps practice, optimized cloud costs early on, saving $100M+.',
      },
      picture: 'stefan_denk.png'
    },
    {
      name: 'Roland Krummenacher',
      slug: '',
      role: 'Senior advisor',
      bio: {
        bio: 'Azure Architect since 2011 in finance/manufacturing. Enabled Swisscom CSP growth and optimized Azure costs at SwissRe. FinOps advocate and active community contributor.',
      },
      picture: 'roland_krummenacher.png'
    },
  ],
}
