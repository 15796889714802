import React from 'react'
import { styled } from '@linaria/react'
import { BookAFreeMeetingButton, SectionContainer } from '../components'
import { expertTexts } from '../_DATA'
import { AuthorCard, type Author } from '@/blocks/AuthorCard'
import { media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { useGetImagesFromAssets, FileNode } from '@/hooks'

interface Props {}

export const ExpertTeam = ({}: Props) => {
  const images = useGetImagesFromAssets({})
  const dataFinal = convertData(expertTexts.experts, images)

  return (
    <SectionContainer title={expertTexts.title} subTitle={expertTexts.subtitle} headerContentGap="3rem">
      <ExpertTeamGrid>
        {dataFinal.map(expert => (
          <AuthorCard key={expert.name} author={expert} isExternalPerson />
        ))}
      </ExpertTeamGrid>
      <BookAFreeMeetingButton />
    </SectionContainer>
  )
}

const ExpertTeamGrid = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  align-self: center;
  margin-bottom: 2rem;

  ${media.desktopSmall} {
    padding: 0 ${pxToRem(72)};
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
    margin-bottom: ${pxToRem(72)};
  }
`

function convertData(experts: typeof expertTexts.experts, nodes: FileNode[]): Author[] {
  return experts.map(expert => {
    const expertNode = nodes.find(({ base }) => base === expert.picture)

    if (!expertNode) return expert

    return {
      ...expert,
      avatar: {
        gatsbyImageData: expertNode.childImageSharp.gatsbyImageData,
      },
    }
  })
}
