import React from 'react'
import { styled } from '@linaria/react'
import { makeH2Styles, makeTextBaseStyles } from '@/UI'
import { media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { comparisonTexts } from '../../_DATA'
import { CompTable } from './CompTable'
import { BookAFreeMeetingButton, SectionContainer } from '../../components'

interface Props {}

export const Comparison = ({}: Props) => {
  return (
    <SectionContainer>
      <Title>{comparisonTexts.title}</Title>
      <CompTable table={comparisonTexts.table} />
      <Text>{comparisonTexts.text}</Text>
      <BookAFreeMeetingButton />
    </SectionContainer>
  )
}


const Title = styled.div`
  ${makeH2Styles({})};
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;

  ${media.desktopSmall} {
    margin-bottom: ${pxToRem(72)};
  }
`

const Text = styled.span`
  max-width: ${pxToRem(776)};
  align-self: center;
  text-align: center;
  padding-bottom: ${pxToRem(48)};
  opacity: 0.6;
  ${makeTextBaseStyles({ variant: 'small' })};
  
`
