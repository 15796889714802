import React, { useEffect } from 'react'
import { styled } from '@linaria/react'
import { useAnimate } from 'react-simple-animate'
import { colors } from '@/lib/theme'

interface Props {
  isOn: boolean
}

export const Indicator = ({ isOn }: Props) => {
  const { play, style } = useAnimate({
    start: { transform: 'rotate(0deg)' },
    end: { transform: 'rotate(45deg)' },
  })

  useEffect(() => {
    play(isOn)
  }, [isOn])

  return (
    <IndicatorView>
      <SvgContainer style={style}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={colors.textStyles.regular} strokeWidth="2">
          <path d="M12 5V19 M5 12H19" />
        </svg>
      </SvgContainer>
    </IndicatorView>
  )
}

const IndicatorView = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

const SvgContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  transform-origin: center;
`
