import React from 'react'
import { styled } from '@linaria/react'
import { css } from '@linaria/core'
import { ContactFormWithWrapper, SideBlock } from '@/blocks/contact-form'
import { Locations } from '~/features/info/contact/Locations'
import { SectionContainer } from '../components'

interface Props {}

export const Form = ({}: Props) => {
  return (
    <SectionContainer appearance="gray" hasNoWrapper contentIsCentered>
      <ContactFormWithWrapper title="Send us a message" messageHint="Describe your challenges">
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-self: end;
          `}
        >
          <SideBlock isNoBgOnMobile />
          <Locations />
        </div>
      </ContactFormWithWrapper>
    </SectionContainer>
  )
}

export const FormSideBlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: end;
`
