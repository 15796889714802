import React from 'react'
import { Index } from 'react-instantsearch'
import { SingleSearchIndex } from '_/config/search/searchIndices'
import { CustomHitsSection } from './CustomHitsSection'
import { HeaderSectionsItemsWrapper } from '../../common'

export const SearchResultContent = ({ indices }: { indices: SingleSearchIndex[] }) => {
  return (
    <HeaderSectionsItemsWrapper>
      {indices.map(({ name, title }) => (
        <Index key={name} indexName={name}>
          <CustomHitsSection sectionName={name} sectionTitle={title} />
        </Index>
      ))}
    </HeaderSectionsItemsWrapper>
  )
}
