import React, { ComponentPropsWithoutRef } from 'react'
import { styled } from '@linaria/react'
import { colors, hoverMedia } from '@/lib/theme'
import { Styleable } from '@/lib/interfaces'

interface Props extends ComponentPropsWithoutRef<'button'>, Styleable {
  gridArea?: string
  customColor?: string
  onClick?: () => void
}

export const IconButtonCommon = ({ onClick, children, gridArea, customColor, ...rest }: Props) => {
  return (
    <Container onClick={onClick} $gridArea={gridArea} $customColor={customColor} {...rest}>
      {children}
    </Container>
  )
}

const Container = styled.button<{ $gridArea?: string; $customColor?: string }>`
  color: ${({ $customColor }) => $customColor || colors.white};
  grid-area: ${({ $gridArea }) => $gridArea || ''};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  ${hoverMedia} {
    &:hover {
      opacity: 0.65;
    }
  }

  &:focus {
    opacity: 0.65;
  }
`
