import React from 'react'
import { useSnapshot } from 'valtio'
import { styled } from '@linaria/react'
import { menuSectionsToDisplay } from '../presets'
import { HeaderMenuItem } from '../../common'
import { setActiveNavSection, navSectionsState } from '../state'
import { TransitionFade } from '@/UI'



export const RootMenuItemsMobile = () => {
  const { activeSection } = useSnapshot(navSectionsState)

  return (
    <TransitionFade isVisible={!activeSection} duration={200} durationOut={0}>
      <RootMenuItemsMobileView>
        {menuSectionsToDisplay.map(section => (
          <HeaderMenuItem key={section} itemType="root" text={section} onClick={() => setActiveNavSection(section)} />
        ))}
      </RootMenuItemsMobileView>
    </TransitionFade>
  )
}

const RootMenuItemsMobileView = styled.div`
  margin-top: 1.5rem;
  display: grid;
  position: absolute;
  z-index: 1;
  width: 100%;
`
