import { CSSProperties } from '@linaria/core'
import { colors, fontLeagueSpartan, fontLibreBaskerville, media, theme } from '@/lib/theme'
import { pxToRem, responsiveFontSizes, ResponsiveFontSizesProps } from '@/utils/unitsInRem'

export const hBase: CSSProperties = {
  fontFamily: fontLeagueSpartan,
  fontWeight: 700,
  lineHeight: 1.1,
  letterSpacing: '-0.01em',
}

export const h1StylesBase: CSSProperties = {
  ...hBase,
  fontSize: pxToRem(36),

  [media.desktopSmall]: {
    lineHeight: 1.05,
  },
}

export const makeH2Styles = ({
  base = 24,
  desktopSmall = 36,
}: Pick<ResponsiveFontSizesProps, 'base' | 'desktopSmall'>) => {
  return {
    ...hBase,
    ...responsiveFontSizes({ base, desktopSmall }),
  }
}

export const makeH3Styles = ({
  base = 24,
  desktopSmall = 24,
}: Pick<ResponsiveFontSizesProps, 'base' | 'desktopSmall'>) => {
  return {
    ...hBase,
    fontWeight: 500,
    ...responsiveFontSizes({ base, desktopSmall }),
  }
}

export const textBaseStyles: CSSProperties = {
  fontFamily: fontLibreBaskerville,
  fontSize: pxToRem(16),
  fontWeight: 400,
  lineHeight: 1.65,
}

export type BodyVariantType = 'small' | 'normal' | 'large'

export const bodyFontSizes: Record<BodyVariantType, number> = {
  small: 14,
  normal: 16,
  large: 18,
}

export const makeTextBaseStyles = ({ variant = 'normal' }: { variant?: BodyVariantType }) => ({
  ...textBaseStyles,
  fontSize: pxToRem(bodyFontSizes[variant]),
})

export const textLinkStyles: CSSProperties = {
  color: colors.secondary.darkest,
  transitionTimingFunction: 'ease-in-out',
  transitionDuration: '0.2s',
  transitionProperty: 'color',
  textDecoration: 'underline',

  [media.hover]: {
    '&:hover': {
      color: theme.hover,
    },
  },
  '&:active': {
    textDecoration: 'none',
    color: theme.active,
  },
}

export const mainElementOffsets: CSSProperties = {
  margin: '32px 0',

  [media.desktopSmall]: {
    margin: 'var(--content-blocks-gap) 0',
  },

  '& + *': {
    marginTop: '0 !important',
  },
}
