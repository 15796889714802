import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'
import {
  mainElementOffsets,
  textLinkStyles,
  textBaseStyles,
  makeH2Styles,
  makeH3Styles,
  type BodyVariantType,
  h1StylesBase,
  bodyFontSizes,
} from './element-styles'
import { TitleMain } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'

interface Props {
  $isInverse?: boolean
  $h1Variant?: 'Large' | 'XXL'
  $bodyVariant?: BodyVariantType
}

export const Typography = styled.div<Props>`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - var(--layout-indent) * 2);
  color: ${({ $isInverse }) => ($isInverse ? colors.textStyles.inverse : colors.textStyles.regular)};
  ${textBaseStyles};
  font-size: ${({ $bodyVariant = 'Normal' }) => pxToRem(bodyFontSizes[$bodyVariant])};

  & :first-child {
    margin-top: 0;
  }

  & p:not(:first-child) {
    margin-top: 1em;
  }

  & span {
    &:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }

  & i {
    font-style: normal;
  }

  & a {
    font-weight: 500;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    max-width: 100%;
    ${textLinkStyles};
    display: inline;
  }

  & b {
    font-weight: 700;
  }

  ${TitleMain} {
  }

  & h1 {
    ${h1StylesBase};
    color: ${({ $isInverse }) => ($isInverse ? colors.textStyles.inverse : colors.textStyles.regular)};

    ${media.desktopSmall} {
      font-size: ${({ $h1Variant = 'Large' }) => ($h1Variant === 'Large' ? pxToRem(60) : pxToRem(76))};
      font-weight: ${({ $h1Variant = 'Large' }) => ($h1Variant === 'Large' ? 600 : 500)};
      letter-spacing: ${({ $h1Variant = 'Large' }) => ($h1Variant === 'Large' ? '-0.01em' : '-0.015em')};
    }

    & + * {
      margin-top: 24px !important;

      ${media.desktopSmall} {
        margin-top: 32px !important;
      }
    }
  }

  & h2 {
    ${makeH2Styles({})};
    margin-top: var(--content-blocks-gap);

    & + h3 {
      margin-top: 32px !important;
    }

    & + p {
      margin-top: 24px !important;

      ${media.desktopSmall} {
        margin-top: 32px !important;
      }
    }
  }

  & h3 {
    ${makeH3Styles({})};
    margin-top: 32px;

    & + p {
      margin-top: 8px !important;

      ${media.desktopSmall} {
        margin-top: 16px !important;
      }
    }
  }

  & h4 {
    font-size: ${pxToRem(16)};
  }

  & ul {
    list-style-type: disc;
  }

  & blockquote {
    ${mainElementOffsets};
    padding: 8px 0 8px 32px;
    font-size: ${pxToRem(18)};
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.01em;
    border-left: 1px solid ${colors.tertiary.lightest};

    & p {
      margin: unset;
    }
  }

  & ol,
  ul {
    margin-left: 36px;
    margin-bottom: 0;

    & li {
      margin-bottom: 0;

      &:first-of-type {
        margin-top: 1em;
      }

      & li {
        &:first-of-type {
          margin: unset;
        }
      }

      & p {
        margin: unset;
      }
    }
  }

  & table {
    margin-top: 32px;
    font-family: ${fontLeagueSpartan};
    font-size: ${pxToRem(16)};
    font-weight: 400;
    line-height: 113%;
    letter-spacing: 0.015em;
    max-width: 100%;
    align-self: stretch;
    overflow-x: scroll;
    display: flex;

    & + h2,
    & + h3,
    & + p {
      margin-top: var(--content-blocks-gap) !important;
    }

    & tbody {
      min-width: 100%;
    }

    & p {
      margin: 0;
    }

    & th,
    td {
      text-align: start;
      padding: 16px 32px;
    }

    & th {
      font-weight: 500;
      background-color: ${colors.secondary.lightest};
      border: 1px solid ${colors.white};
      color: white;
    }

    & td {
      border: 1px solid ${colors.white};
    }

    tr:nth-child(odd) {
      background-color: ${colors.background.grayLightest};
    }
  }
`
