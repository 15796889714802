import React from 'react'
import { styled } from '@linaria/react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors, desktopSmallMedia, mobileModernMedia } from '@/lib/theme'
import { Wrapper, TitleH2 } from '@/UI'

export const OurPartners = () => {
  const data = useStaticQuery<Queries.OurPartnersQueryQuery>(graphql`
    query OurPartnersQuery {
      homePageLogos: allContentfulPartners(
        sort: [{ order: ASC }, { contentfulid: ASC }]
        filter: { isVisible: { eq: true } }
      ) {
        nodes {
          id
          contentfulid
          url
          logo {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              height: 104
              resizingBehavior: SCALE
              layout: FIXED
            )
          }
        }
      }
    }
  `)

  const logos = data.homePageLogos.nodes

  return (
    <Root>
      <Inner $noMobilePadding>
        <TitleH2 $isInverted $gap={48} $isCenteredOnMob>
          Our Partners:
        </TitleH2>
        <Logos>
          {logos.map(logo => (
            <Link key={logo.id} to={logo.url} target="_blank" rel="noreferrer">
              <LogoItem key={logo.id}>
                <GatsbyImage
                  image={logo.logo.gatsbyImageData}
                  alt={logo.contentfulid}
                  loading="lazy"
                  objectFit="contain"
                />
              </LogoItem>
            </Link>
          ))}
        </Logos>
      </Inner>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  background-color: ${colors.secondary.secondary};
  padding: 64px 0;
`

const Inner = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 60px -32px 60px;
  align-items: center;

  ${mobileModernMedia} {
    margin: 0 96px -32px 96px;
  }

  ${desktopSmallMedia} {
    margin: 0 0 -32px -32px;
    flex-direction: row;
    min-height: 104px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
const LogoItem = styled.div`
  display: inline-flex;
  height: 104px;
  max-width: 200px;
  margin-bottom: 32px;

  ${desktopSmallMedia} {
    margin-left: 32px;
  }
`
