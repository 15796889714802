export const processTexts = {
  title: 'Our process',
  subTitle: 'A clear path from discovery to sustainable cloud savings and team empowerment.',
  elements: [
    {
      title: 'Assess',
      text: 'We rapidly analyze your cloud usage to uncover hidden inefficiencies.',
    },
    {
      title: 'Act',
      text: 'We implement immediate optimizations for fast, measurable cost reductions.',
    },
    {
      title: 'Optimize',
      text: 'We rapidly analyze your cloud usage to uncover hidden inefficiencies.',
    },
    {
      title: 'Empower',
      text: 'We train your team in FinOps best practices for long-term independence.',
    },
    {
      title: 'Support',
      text: 'Our experts remain available for guidance as you transition to self-sufficiency.',
    },
  ],
}
