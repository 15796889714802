import React from 'react'
import { styled } from '@linaria/react'
import { colors, media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'
import { makeTextBaseStyles } from '@/UI'
import { ServiceIcon } from './ServiceIcon'
import { SmallTitle } from '../../../components'

interface Props {
  content: {
    title: string
    text: string
  }[]
}

export const ServicesMain = ({ content }: Props) => {
  return (
    <ServicesMainView>
      {content.map(({ title, text }, idx) => (
        <Service key={`${title}__${idx}`}>
          <IconContainer>
            <ServiceIcon index={idx} />
          </IconContainer>
          <TextsContainer>
            <SmallTitle $isInverse>
              {title}
            </SmallTitle>
            <Text>{text}</Text>
          </TextsContainer>
        </Service>
      ))}
    </ServicesMainView>
  )
}

const ServicesMainView = styled.div`
  display: grid;
  border-radius: 1.5rem;
  margin-bottom: 2rem;
  color: ${colors.white};
  grid-gap: 3rem;
  background-color: ${colors.secondary.secondary};
  padding: ${pxToRem(32, 48)};
  align-items: start;


  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.desktopSmall} {
    padding: ${pxToRem(56, 80)};
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: ${pxToRem(72)};
  }
`

const Service = styled.div`
  display: grid;
  grid-template-rows: 40px auto;
  grid-gap: 1.5rem;
`

const TextsContainer = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
`

const IconContainer = styled.div`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
`

const Text = styled.span`
  ${makeTextBaseStyles({})};
  color: ${colors.white};
`
