import { proxy } from 'valtio'
import { derive, devtools } from 'valtio/utils'

export const mobSearchState = proxy<{ isSearchVisible: boolean }>({ isSearchVisible: false })
export const showSearch = () => {
  mobSearchState.isSearchVisible = true
}
export const hideSearch = () => {
  mobSearchState.isSearchVisible = false
}

export const searchQueryState = proxy<{ query: string }>({ query: '' })
export const setSearchQuery = (query: string) => {
  searchQueryState.query = query
}
export const resetSearchQuery = () => {
  searchQueryState.query = ''
}
export const isHasSearchQuery = derive({
  value: get => Boolean(get(searchQueryState).query.length),
})

export const searchQueryHitsCount = proxy<{value: Record<string, boolean>}>({value: {}})
export const resetHitsCount = () => {
  searchQueryHitsCount.value = {}
}
export const hasSearchResults = derive({
  value: get => {
    const state = get(searchQueryHitsCount)
    return Object.values(state.value).includes(true)
  }
})

// export const unsub = devtools(navState, { name: 'navState', enabled: true })
