import React from 'react'
import { styled } from '@linaria/react'
import { BookAFreeMeetingButton, SectionContainer, TitleBlock } from '../components'
import { topBlockTexts } from '../_DATA'
import { pxToRem } from '@/utils/unitsInRem'
import { css } from '@linaria/core'
import { media } from '@/lib/theme'
import { Wrapper } from '@/UI'

interface Props {}

export const TopBlock = ({}: Props) => {
  return (
    <StyledSectionContainer appearance="dark" hasNoWrapper>
      <ImageBlock>
        <img src="/services/azure-logo.svg" alt="azure logo" />
      </ImageBlock>
      <Wrapper>
        <Inner>
          <StyledTitleBlock
            title={topBlockTexts.title}
            subTitle={topBlockTexts.subTitle}
            marginBottom={pxToRem(48)}
            alignment="start"
            titleIsInverse
          />
          <BookAFreeMeetingButton />
        </Inner>
      </Wrapper>
    </StyledSectionContainer>
  )
}

const StyledSectionContainer = styled(SectionContainer)`
  overflow: hidden;
  display: flex;

  ${media.desktopSmall} {
    align-items: center;
    justify-content: center;
    height: ${pxToRem(611)};
  }
`

const StyledTitleBlock = styled(TitleBlock)`
  ${media.desktopSmall} {
    margin-top: 0;
    max-width: ${pxToRem(600)};
  }
`

const Inner = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 22rem;

  ${media.desktopSmall} {
    margin-top: 0;
    padding: ${pxToRem(0, 80)};
  }
`

const ImageBlock = styled.div`
  position: absolute;
  right: 0;
  width: 30rem;
  top: 8rem;

  ${media.desktopSmall} {
    top: 128px;
    width: ${pxToRem(855)};
    height: ${pxToRem(611)};
  }
`
