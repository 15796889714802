import { styled } from '@linaria/react'
import { colors, fontLeagueSpartan, media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

export const SmallTitle = styled.span<{
  $isInverse?: boolean
  $isNotFat?: boolean
  $bottomGapPx?: number
  $isCentered?: boolean
}>`
  font-family: ${fontLeagueSpartan};
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.1;
  flex-shrink: 0;
  flex-grow: 0;
  letter-spacing: -0.01em;
  font-weight: ${({ $isNotFat }) => ($isNotFat ? 500 : 700)};
  color: ${({ $isInverse }) => ($isInverse ? colors.textStyles.inverse : colors.textStyles.regular)};
  margin-bottom: ${({ $bottomGapPx = 0 }) => pxToRem($bottomGapPx)};
  text-align: start;
  
  ${media.desktopSmall} {
    text-align: ${({ $isCentered }) => ($isCentered ? 'center' : 'start')};
  }
`
