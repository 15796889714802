import { useCallback } from 'react'
import { hideSearch, resetSearchQuery, resetHitsCount } from '../components/search/state'

export function useCloseSearchHandler(callback?: () => void) {
  return useCallback(() => {
    resetSearchQuery()
    hideSearch()
    resetHitsCount()
    if(callback) callback()
  }, [callback])
}
