import { styled } from '@linaria/react'
import { media } from '@/lib/theme'
import { pxToRem } from '@/utils/unitsInRem'

export const Wrapper = styled.div<{
  $noMobilePadding?: boolean
  $noUltraThinPadding?: boolean
  $noDesktopPadding?: boolean
  $displayProperty?: 'block' | 'flex' | 'grid'
  $flexDirectionProperty?: 'column' | 'row'
}>`
  margin: 0 auto;
  width: 100%;
  display: ${({ $displayProperty = 'block' }) => $displayProperty};
  flex-direction: ${({ $flexDirectionProperty }) => $flexDirectionProperty ?? 'unset'};
  padding: ${({ $noMobilePadding, $noUltraThinPadding }) =>
    !$noUltraThinPadding && !$noMobilePadding ? '0 var(--layout-indent)' : 'none'};

  ${media.mobileModern} {
    padding: ${({ $noMobilePadding }) => (!$noMobilePadding ? '0 var(--layout-indent)' : 'none')};
  }

  ${media.desktopSmall} {
    max-width: ${({ $noDesktopPadding }) => (!$noDesktopPadding ? pxToRem(1440) : 'unset')};
    padding: ${({ $noDesktopPadding }) => (!$noDesktopPadding ? `0 ${pxToRem(72)}` : '0')};
  }
`
