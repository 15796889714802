import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'

export const navSectionsState = proxy<{ activeSection: string | null }>({ activeSection: null })
export const setActiveNavSection = (id: string) => {
  navSectionsState.activeSection = id
}
export const removeActiveNavSection = () => {
  navSectionsState.activeSection = null
}

export const navStateMobile = proxy<{ isNavVisible: boolean }>({ isNavVisible: false })
export const showNavMobile = () => {
  navStateMobile.isNavVisible = true
}
export const hideNavMobile = () => {
  navStateMobile.isNavVisible = false
}
export const toggleNavMobile = () => {
  if (navStateMobile.isNavVisible) {
    hideNavMobile()
  } else {
    showNavMobile()
  }
}

export const unsub = devtools(navSectionsState, { name: 'navSectionsState', enabled: true })
