import React from 'react'
import { css } from '@linaria/core'
import { pxToRem } from '@/utils/unitsInRem'
import { SectionContainer, Accordion, AccordionSectionContentType } from '../components'
import { FAQTexts } from '../_DATA'

type FaqSectionType = {
  question: string
  answer: string
}
interface Props {}

export const FAQ = ({}: Props) => {
  return (
    <SectionContainer title="FAQ" appearance="dark" headerContentGap={pxToRem(32)}>
      <Accordion
        content={convertData(FAQTexts)}
        className={css`
          max-width: ${pxToRem(856)};
          justify-self: center;
          align-self: center;
        `}
      />
    </SectionContainer>
  )
}

function convertData(input: FaqSectionType[]): AccordionSectionContentType[] {
  return input.map(({ question, answer }) => ({ title: question, text: answer }))
}
