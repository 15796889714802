import React from 'react'
import { styled } from '@linaria/react'
import { useSnapshot } from 'valtio'
import { menuSectionsToDisplay } from '../presets'
import { navSectionsState } from '../state'
import { OpacityCommon } from './styles'
import { useGetMenuItems, useCloseNavHandler } from '../hooks'
import { HeaderMenuSection, HeaderSectionsItemsWrapper } from '../../common'

export const MenuSections = () => {
  const menuSections = useGetMenuItems()
  const { activeSection } = useSnapshot(navSectionsState)
  const closeNav = useCloseNavHandler()

  return menuSectionsToDisplay.map(menuSection => (
    <NavSectionView $isVisible={activeSection === menuSection}>
      <HeaderSectionsItemsWrapper as="nav">
        {menuSections[menuSection].map(({ groupName, groupItems }) => (
          <HeaderMenuSection title={groupName} data={groupItems} onClick={closeNav} />
        ))}
      </HeaderSectionsItemsWrapper>
    </NavSectionView>
  ))
}

const NavSectionView = styled(OpacityCommon)`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: start;
  align-items: start;
`
