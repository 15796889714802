/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { styled } from '@linaria/react'
import { Link } from 'gatsby'
import { css, cx } from '@linaria/core'
import { colors, media } from '@/lib/theme'
import { Arrow } from '@/UI'
import { pxToRem } from '@/utils/unitsInRem'
import { HeaderMenuItemDataType } from './types'

interface Base {
  onClick?: () => void
}

interface PropsRoot extends Base {
  itemType: 'root'
  text: string
}

interface PropsLink extends Base {
  itemType?: 'link'
  data: HeaderMenuItemDataType
}

export const HeaderMenuItem = (props: PropsRoot | PropsLink) => {
  const isRoot = props.itemType === 'root'

  const content = (
    <>
      <Text $isRootItem={isRoot}>{isRoot ? props.text : props.data.title}</Text>
      <ArrowStyled direction="right" />
    </>
  )

  if (isRoot) {
    return (
      <StyledLi>
        <button className={cx(ItemStyles, RootSvgStyles)} onClick={props.onClick} tabIndex={0}>
          {content}
        </button>
      </StyledLi>
    )
  }

  return (
    <StyledLi>
      <Link className={ItemStyles} to={props.data.url} onClick={props.onClick} tabIndex={0}>
        {content}
      </Link>
    </StyledLi>
  )
}

const ActiveStyle = {
  color: colors.primary.primary,
  borderTopColor: colors.primary.primary,
  borderRightColor: colors.primary.primary,
  borderLeftColor: colors.primary.primary,

  '&:last-child': {
    borderBottomColor: colors.primary.primary,
  },

  '& + li': {
    borderTopColor: colors.primary.primary,
  },

  '& svg': {
    fill: colors.primary.primary,
  },
}

const StyledLi = styled.li`
  list-style-type: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  user-select: none;
  color: rgba(255, 255, 255, 0.8);
  transition-property: color, border-color, background-color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;

  &:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  ${media.hover} {
    &:hover {
      ${ActiveStyle};
    }
  }

  &:focus {
    ${ActiveStyle};
  }

  &:focus-visible {
    ${ActiveStyle};
  }

  &:focus-within {
    ${ActiveStyle};
  }

  &:active {
    ${ActiveStyle};
    color: ${colors.secondary.darkest};
    background-color: ${colors.primary.primary};

    & svg {
      fill: ${colors.secondary.darkest};
    }
`

const Text = styled.span<{ $isRootItem: boolean }>`
  font-size: ${({ $isRootItem }) => ($isRootItem ? pxToRem(20) : pxToRem(18))};
  font-weight: ${({ $isRootItem }) => ($isRootItem ? 700 : 400)};
  line-height: 100%;
  letter-spacing: 0.02em;
  text-align: left;
`

const ItemStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pxToRem(18, 16, 14, 16)};
  cursor: pointer;
  width: 100%;

  ${media.desktopSmall} {
    padding: ${pxToRem(18, 20, 14, 20)};
  }

  & svg {
    fill: transparent;
    transition: fill 0.2s ease-in-out;
  }
`

const RootSvgStyles = css`
  & svg {
    fill: currentColor;
  }
`

const ArrowStyled = styled(Arrow)`
  margin-left: 16px;
  transform: translateY(-2px) scaleX(-1);
`
