import React from 'react'
import { styled } from '@linaria/react'
import { RootMenuItemsMobile } from './RootMenuItemsMobile'
import { MenuSections } from './MenuSections'

export const MenuListMob = () => {
  return (
    <RelativeWrapper>
      <RootMenuItemsMobile />
      <MenuSections />
    </RelativeWrapper>
  )
}
const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
`
